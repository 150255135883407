import React, { ChangeEvent } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Dropdown,
  Form,
  FormGroup,
  Button,
  Modal,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Spinner,
  Accordion,
  Card,

  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FaPencilAlt } from 'react-icons/fa';
import BootstrapTable from "react-bootstrap-table-next";
import { ConfirmationModal } from "../Common/Components/ConfirmationModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { JobServices } from "../Common/Services/JobServices";
import DatePicker from "react-datepicker";
import { Utils } from "../Common/Utilis";
import { VendorService } from "../Common/Services/VendorService";
import { Customerorderslideout } from './Customerorderslideout';
import { resolve, reject } from "q";
import chevronRight from "../Common/Assets/Images/icons/bx-chevron-right.svg";
import "../App.scss";
import "./slideout.scss";
import { VendorSearch } from "../Common/Components/VendorSearch";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { FaArrowUpFromBracket, FaCircleMinus, FaCirclePlus, FaEllipsis } from "react-icons/fa6";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import moment from "moment";
import { CustomerService } from "../Common/Services/CustomerService";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { JobCreations } from './JobCreations';
import { MdDesignServices } from "react-icons/md";
import { MdBuild } from 'react-icons/md';
import { confirmAlert } from "react-confirm-alert";
import { SingleDropdownListWithoutSearchBoot } from "../Common/Components/SingleDropdownListWithoutSearchBoot";
import { DropdownMultiSelect } from "../Common/Components/DropdownMultiSelect";
import { PartTemplateService } from "../Common/Services/PartTemplateService";
import { debug } from "console";
import Dropzone from "react-dropzone";
import {
  faPlus,
  faMinus,
  faPaperclip,
  faFile,
  faFileImage,
  faFileExcel,
  faFilePdf,
  faFileCsv,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import ScrollContainer from "react-indiana-drag-scroll";
import { CommentBox } from "../Common/Components/CommentBox";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import PDFViewer from "./pdf-viewer";
import { truncate } from "fs";
import { SearchMultiSelectItems } from "../Common/Components/SearchMultiSelectItems";
let routeLeavingGuard: any = null;
let storage = JSON.parse(localStorage.getItem("storage")!);
let currentUtcTime = storage === null ? "America/Chicago" : (storage.currentUtcTime as any);
const formattedDate = Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime);

export class Vendororderslideout extends React.Component<any, any> {
  private customerSearch: any;
  private trDate: any;
  private parentScroll: any;
  private ChildComment: any;

  renderTooltip = (ids) => {
    return (
      <Tooltip className="LDtoolTipLettercodes" id="button-tooltip" {...ids}>
        {ids.toolTipLettercodes.replaceAll(",", ", ")}
      </Tooltip>
    );
  };
  LatterCodeFormater = (cell, row, rowIndex) => {
    if (row.deptName === "" && row.lettercode === "") {
      return <div style={{ color: "gray" }}>Select EHID</div>;
    } else if (row.lettercode === "" && row.deptName !== "") {
      return (
        <div className="required-cell-text">
          EHID <div>Required</div>
        </div>
      );
    } else {
      return (
        <div

          onWheel={this.hoverOutEllipsis.bind(this)}
        >

          <>
            {!row.lettercode.includes("+") ? (
              <span>{row.lettercode}</span>
            ) : (
              <OverlayTrigger
                placement="top"
                delay={{ show: 0, hide: 0 }}
                overlay={this.renderTooltip(row)}
              >
                <span>{row.lettercode}</span>
              </OverlayTrigger>
            )}
          </>

        </div>
      );
    }
  };
  private icheckselectedall = (column, colIndex) => {
    return (
      <div className="d-flex align-items-center txt-bold">
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="postingRule">Click to copy below all parts</Tooltip>
          }
        >
          <FontAwesomeIcon
            icon={faArrowUp}
            size="xs"
          // onClick={(e) => this.handleRowSelectionCheckAll()}
          />
        </OverlayTrigger>
        {/* <input

                type="checkbox"
                onChange={(e) => this.handleRowSelectionAll(e, colIndex)}
            /> */}
      </div>
    );
  };

  private icheckselectedalljob = (column, colIndex) => {
    return (
      <div className="d-flex align-items-center txt-bold">
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="postingRule">Click to copy below all parts</Tooltip>
          }
        >
          <FontAwesomeIcon
            icon={faArrowUp}
            size="xs"
            onClick={(e) => this.handleRowSelectionCheckAllJob()}
          />
        </OverlayTrigger>
        {/* <input

                type="checkbox"
                onChange={(e) => this.handleRowSelectionAll(e, colIndex)}
            /> */}
      </div>
    );
  };


  private transactionsColumns = [

    {
      dataField: "",
      text: "Sr No.",
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {

        return (
          <div>
            {rowIndex + 1}
          </div>
        );
      },
    },
    {
      dataField: "itemName",
      text: "Item Name",
      headerClasses: "coa",

      headerStyle: { width: "900px", textAlign: "start" },
      classes: "text-start",
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            {row.itemName}
          </div>
        );
      },
    },
    {
      dataField: "itemDesc",
      text: "Item Description",
      headerClasses: "coa",
      hidden: true,
      headerStyle: { width: "400px", textAlign: "left" },
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtitemDesc" + rowIndex + "txt";
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              placeholder="Please enter item details"
              value={row.itemDesc}
              onChange={(e) => this.updateState(e, row, "itemDesc", id)}
              autoComplete="off"
              //pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={200}
              disabled={false}
              title=""
            />
          </div>
        );
      },
    },
    {
      dataField: "glcode",
      text: "MS Code",
      headerStyle: { width: "100px" },
      hidden: true,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtglcode" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              placeholder=""
              value={row.glCode}
              onChange={(e) => this.updateState(e, row, "glcode", id)}
              autoComplete="off"
              //pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={200}
              disabled={false}
              title=""
            />
            {/* {row.glCode}           */}
          </div>
        );
      },
    },
    {
      dataField: "unit",
      text: "Unit",
      headerStyle: { width: "100px" },
      hidden: true,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtunit" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={row.unit}
              onChange={(e) => this.updateState(e, row, "unit", id)}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={false}
              title=""
            />
          </div>
        );
      },
    },

    {
      dataField: "qty",
      text: "Qty",
      headerStyle: { width: "250px" },
      hidden: true,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtreqqty" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={row.qty}
              onChange={(e) => this.updateState(e, row, "qty", id)}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={false}
              title=""
            />
          </div>
        );
      },
    },

    {
      dataField: "unitPrice",
      text: "Unit Price",
      headerStyle: { width: "100px" },
      hidden: true,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtunitPrice" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={row.unitPrice}
              onChange={(e) => this.updateState(e, row, "unitPrice", id)}
              onBlur={(e: any) => this.amountBlur(e, rowIndex, "unitPrice", row)}
              onFocus={(e: any) => this.onAmountFocus(e, rowIndex, id, "unitPrice")}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={false}
              title=""
            />
          </div>
        );
      },
    },

    {
      dataField: "amount",
      text: "Amount",
      hidden: true,
      headerStyle: { width: "150px" },
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtamount" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={Utils.currencyFormat(Number(Utils.removeInvaildCharFromAmount(row.unitPrice)) * row.qty)}
              onChange={(e) => this.updateState(e, row, "amount", id)}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={disable}
              title=""
            />
          </div>
        );
      },
    },


  ];

  private templateColumns = [
    {
      dataField: "isSelected",
      text: "",
      headerFormatter: this.icheckselectedalljob,
      classes: "expanding-bar",
      formatter: (cell: any, row: any, rowIndex: any) => {
        // const prevOrderidselected = mainrow.orderid;
        // this.setState({ prevOrderidselected: prevOrderidselected });
        return (
          <div className="d-flex align-items-center txt-bold">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="toolp">Click to copy this part</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faArrowUp}
                size="xs"
                onClick={(e) =>
                  this.handleRowSelectionCheckJob(
                    e,
                    rowIndex,
                    row,
                    "true"
                  )
                }
              />
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Sr No.",

      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {

        return (
          <div>
            {rowIndex + 1}
          </div>
        );
      },
    },
    {
      dataField: "JobNo",
      text: "Job No",

      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <EllipsisWithTooltip placement="bottom">
            <Button className="btn-link" onClick={() => this.showSlideOut(row, 'job')}>
              {row.jobNo}
            </Button>
          </EllipsisWithTooltip>
        );
      },
    },

    // {
    //   dataField: 'type',
    //   text: 'Type',
    //   headerStyle: { width: '150px' },
    //   hidden: false,
    //   editable: false,
    //   headerAttrs: (column, colIndex) => (
    //     { 'tabIndex': '-1' }
    //   ),
    //   formatter: (cell: any, row: any, rowIndex: any) => {
    //     let id = "txttype" + rowIndex + "txt";
    //     let disable = false;
    //     return (
    //       <div>
    //         {row.type}
    //       </div>
    //     );



    //   },
    // },

    {
      dataField: "customerPoNumber",
      text: "CO No.",
      hidden: false,
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <EllipsisWithTooltip placement="bottom">
            <Button className="btn-link" onClick={() => this.showSlideOut(row, 'customer')}>
              {row.customerPoNumber}
            </Button>

          </EllipsisWithTooltip>

        );
      },
    },
    {
      dataField: 'tdescription',
      text: 'Description',
      headerStyle: { width: '400px', },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),

      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtjdescription" + rowIndex + "txt";
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={row.jdescription}
              onChange={(e) => this.handleChange(e, 'jdescription', rowIndex)}
              autoComplete="off"
              //pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={500}
              disabled={false}
              title=""
            />
          </div>
        );
      },

    },
    {
      dataField: 'processName',
      text: 'Process',
      headerStyle: { width: '200px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            {row.processname}
          </div>
        );
      },

    }
    ,
    {
      dataField: 'orderedQty',
      text: 'Qty',
      headerStyle: { width: '100px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id="txtOrderedQty"
              type="text"
              placeholder="Enter Ordered Qty"
              value={row.qtyOrdered}
              onChange={(event: any) => this.handleChange(event, 'orderedqty', rowIndex)}
              autoComplete="off"

              disabled={false}
              title=""
            />

          </div>
        );
      },

    },
    {
      dataField: 'unitPrice',
      text: 'Unit Price',
      headerStyle: { width: '100px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtUnitPrice" + rowIndex + "txt";
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              placeholder="Enter Unit Price"
              value={row.unitPrice}
              onChange={(event: any) => this.handleChange(event, 'unitprice', rowIndex)}
              onBlur={(e: any) => this.amountBlurProcess(e, rowIndex, "unitPrice", row)}
              onFocus={(e: any) => this.onAmountFocusProcess(e, rowIndex, id, "unitPrice")}
              autoComplete="off"

              disabled={false}
              title=""
            />

          </div>
        );
      },

    },
    {
      dataField: 'amount',
      text: 'Amount',
      headerStyle: { width: '150px' },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id="txtAmount"
              type="text"
              placeholder="Enter Amount"
              value={Utils.currencyFormat(Number(Utils.removeInvaildCharFromAmount(row.unitPrice)) * row.qtyOrdered)}
              //onChange={(event: any) => this.handleChange(event, 'amount')} 
              autoComplete="off"

              disabled={false}
              title=""
            />

          </div>
        );
      },

    }
  ];

  private itemColumns = [
    {
      dataField: "srno",
      text: "No.",
    },
    {
      dataField: "itemName",
      text: "Item Name",
      headerClasses: "coa",

      headerStyle: { width: "500px" },
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),

      formatter: (cell: any, row: any, rowIndex: any) => {
        let btnId = "itemname" + rowIndex + "btn";
        let id = "itemname" + rowIndex + "ddl";
        const defaultitemname = row.defaultitemname;
        let disable = false;
        return (
          // <div className="disabled-area my-class" onClick={(e)=>this.onclickevent(e)}>
          <div
            //  onClick={() => $('#tbltemplate td.reset-expansion-style').css('overflow', 'inherit')}
            style={{ position: 'relative' }}
          >
            <div className="more-action single-select-image dropdown coa-select">

              {this.state.inventorydata.length > 0 && <Typeahead

                id={"itemname" + rowIndex + "ddl"}
                inputProps={{
                  className: "rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex + "ddl",
                  title: defaultitemname,
                  //onClick: () => this.hoverOutEllipsis()
                }}
                options={

                  this.state.inventorydata.filter((ele, ind) => ind === this.state.inventorydata.findIndex(elem => elem.itemname === ele.itemname && ele.itemname !== ""))

                }

                placeholder={"Enter item Name"}

                // labelKey="label"

                renderMenu={(results: any, menuProps) => (
                  <Menu style={{ zIndex: "-1" }}  {...menuProps}>
                    {results.map((result: any, index: any) => (
                      <>
                        {this.state.inventorydata.length > 0}
                        {result.label !== "" && (
                          <MenuItem
                            option={result}
                            position={index}
                          //        onWheel={() => this.hoverOutEllipsis()}
                          >
                            <EllipsisWithTooltip placement="bottom">
                              {result.label}

                            </EllipsisWithTooltip>
                          </MenuItem>
                        )}

                        {result.itemname === "" && (
                          <MenuItem option={result} position={index}></MenuItem>
                        )}
                      </>
                    ))}
                  </Menu>
                )}

                onChange={this.handleSelectedItem.bind(
                  this,
                  1 + "_" + rowIndex + "_" + defaultitemname
                )}
                selected={row.defaultitemname}
                allowNew
                onBlur={this.handleOutsideClick.bind(this, rowIndex)}
              // onKeyDown={this.onTAB.bind(this, "itemname-" + rowIndex.toString() + "ddl", rowIndex)}

              />}


            </div>

            <FaPencilAlt

              onClick={() => this.handleEditClick(rowIndex, row.defaultitemname)}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
              }}
            />

          </div>
        );
      },
    },
    // {
    //   dataField: "CO#",
    //   text: "CO#",
    //   headerClasses: "coa",
    //   headerStyle: { width: "200px" },
    //   headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),

    //   formatter: (cell: any, row: any, rowIndex: any) => {
    //     let btnId = "itemname" + rowIndex + "btn";
    //     let id = "itemname" + rowIndex + "ddl";
    //     const defaultcustomerPoNumber = row.customerPoNumber;
    //     let disable = false;
    //     return (
    //       <div>
    //         <div className="more-action single-select-image dropdown coa-select">

    //           {/* {this.state.CODetails.length > 0 &&  */}
    //           <Typeahead

    //             id={"itemname" + rowIndex + "ddl"}
    //             inputProps={{
    //               className: "rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex + "ddl",
    //               title: defaultcustomerPoNumber,
    //             }}
    //             options={this.state.CODetails}

    //             placeholder={""}
    //             renderMenu={(results: any, menuProps) => (
    //               <Menu style={{ zIndex: "-1" }}  {...menuProps}>
    //                 {results.map((result: any, index: any) => (
    //                   <>
    //                     {this.state.CODetails.length > 0}
    //                     {result.label !== "" && (
    //                       <MenuItem
    //                         option={result}
    //                         position={index}
    //                       >
    //                         <EllipsisWithTooltip placement="bottom">
    //                           {result.label}

    //                         </EllipsisWithTooltip>
    //                       </MenuItem>
    //                     )}

    //                     {result.label === "" && (
    //                       <MenuItem option={result} position={index}></MenuItem>
    //                     )}
    //                   </>
    //                 ))}
    //               </Menu>
    //             )}

    //             onChange={this.handleSelectedItem.bind(
    //               this,
    //               6 + "_" + rowIndex + "_" + defaultcustomerPoNumber
    //             )}
    //             selected={row.defaultcustomerPoNumber}
    //             allowNew
    //             // onBlur={this.handleOutsideClickForAdditionalItems.bind(this, rowIndex)}
    //           // onKeyDown={this.onTAB.bind(this, "itemname-" + rowIndex.toString() + "ddl", rowIndex)}

    //           />
    //           {/* } */}

    //         </div>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   dataField: "JO#",
    //   text: "JO#",
    //   editable: true,

    //   editorRenderer: (
    //     editorProps,
    //     value,
    //     row,
    //     column,
    //     rowIndex,
    //     columnIndex
    //   ) => (
    //     <div className="department-filter">

    //         <SearchMultiSelectItems
    //           itemsList={this.state.JODetails}
    //           itemsSelected={this.itemsSelected}
    //           selectedItems={row.selectedIDs}
    //           {...editorProps}
    //           value={value}
    //           placeholder={"Search Job"}
    //         />

    //     </div>
    //   ),
    // },


    {
      dataField: "JO#",
      text: "JO#",
      headerClasses: "coa",
      // headerStyle: { width: "600px" },
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),

      formatter: (cell: any, row: any, rowIndex: any) => {
        console.log("jo rowww", row);
        let btnId = "itemname" + rowIndex + "btn";
        let id = "itemname" + rowIndex + "ddl";
        const defaultjobno = row.jobNo;
        let disable = false;

        if (!row.JODetails || row.JODetails.length === 0) {
          return (
            <>
            </>
            //   <DropdownMultiSelect
            //   key={rowIndex}
            //   rowIndex={rowIndex}
            //   data={JODetails}
            //   label="label"
            //   value="value"
            //   placeHolder="Select"
            //   filtertitle="Search..."
            //   updateMultiSelectItem={(selectedItem) =>
            //     this.updateMultiSelectItem(selectedItem, rowIndex, 1)
            //   }
            // />
          );
        }

        return (
          <div className="m-1" style={{ width: "350px" }}>
            {row.JODetails && row.JODetails.length > 0 && (
              <DropdownMultiSelect
                key={rowIndex}
                rowIndex={rowIndex}
                data={row.JODetails}
                label="label"
                value="value"
                placeHolder="Select"
                filtertitle="Search..."
                updateMultiSelectItem={(selectedItem) =>
                  this.updateMultiSelectItem(selectedItem, rowIndex, 2)
                }
              />
            )}
          </div>
        );
      },
    },
    // {
    //   dataField: "itemDesc",
    //   text: "Item Description",
    //   headerClasses: "coa",

    //   headerStyle: { width: "400px" },
    //   headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
    //   formatter: (cell: any, row: any, rowIndex: any) => {
    //     let id = "txtitemDesc" + rowIndex + "txt";
    //     return (
    //       <div>
    //         <Form.Control
    //           tabIndex={this.state.tabEnable}
    //           id={id}
    //           type="text"
    //           placeholder="Please enter item details"
    //           value={row.itemDesc}
    //           onChange={(e) => this.updateStateMisc(e, row, "itemDescForMisc", id)}
    //           autoComplete="off"
    //           //pattern="[+-]?\d+(?:[.,]\d+)?"
    //           maxLength={200}
    //           disabled={false}
    //           title=""
    //         />
    //       </div>
    //     );
    //   },
    // },
    ,
    {
      dataField: "glcode",
      text: "MS Code",
      headerStyle: { width: "150px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtglcode" + rowIndex + "txt";
        let disable = false;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              placeholder="Enter MS Code"
              value={row.glCode}
              onChange={(e) => this.updateStateMisc(e, row, "glcode", id)}
              autoComplete="off"
              //onKeyDown={(e: any) => this.onTAB("glcode", rowIndex, e)}
              disabled={disable}
              title=""
            />
          </div>
        );
      },
    },

    {
      dataField: "qty",
      text: "Qty",
      headerStyle: { width: "250px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtreqqty" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={row.qty}
              onChange={(e) => this.updateStateMisc(e, row, "qty", id)}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={false}
              title=""
            />
          </div>
        );
      },
    },

    {
      dataField: "unitPrice",
      text: "Unit Price",
      headerStyle: { width: "100px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtunitPrice" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={row.unitPrice}
              onChange={(e) => this.updateStateMisc(e, row, "unitPrice", id)}
              onBlur={(e: any) => this.amountBlurMisc(e, rowIndex, "unitPrice", row)}
              onFocus={(e: any) => this.onAmountFocusMisc(e, rowIndex, id, "unitPrice")}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={false}
              title=""
            />
          </div>
        );
      },
    },

    {
      dataField: "amount",
      text: "Amount",

      headerStyle: { width: "150px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtamount" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={Utils.currencyFormat(Number(Utils.removeInvaildCharFromAmount(row.unitPrice)) * row.qty)}
              onChange={(e) => this.updateStateMisc(e, row, "amount", id)}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={disable}
              title=""
            />
          </div>
        );
      },
    },
    {
      dataField: 'Attachment',
      text: '',
      //headerFormatter: this.iconFormatter,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let itemDetailConfirmModalContent = [
          {
            title: "Delete Row",
            desc: "Are you sure you want to delete?",
            cancleAction: "Cancel",
            ActionTrue: "Delete",
          },
        ];
        // if (this.state.misclistByItem.length >= 0 ) {
        //   return (
        //     <div></div>
        //   );
        // }
        // else {
        return (
          <Dropdown className="more-action confirmation-modal" tabIndex={this.state.tabDisable}>
            <Dropdown.Toggle className="btn-outline-primary btn btn-primary more fa-trash" id="dropdown-more-delete" tabIndex={this.state.tabDisable}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <ConfirmationModal
                confirmModalContent={itemDetailConfirmModalContent}
                ishandleDeletePosition={this.handleItemDetailDeleteRow.bind(
                  this,
                  row
                )}
              />
            </Dropdown.Menu>
          </Dropdown>
        );
        // }
      },
    },

  ];

  hoverOutEllipsis = () => {
    //$(".tooltip").removeClass("show");
  };


  private AdditionalMaterialItemColumns = [

    {
      dataField: "srno",
      text: "No.",
    },
    {
      dataField: "itemName",
      text: "Item Name",
      headerClasses: "coa",

      headerStyle: { width: "711px" },
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),

      formatter: (cell: any, row: any, rowIndex: any) => {
        let btnId = "itemname" + rowIndex + "btn";
        let id = "itemname" + rowIndex + "ddl";
        const defaultitemname = row.defaultitemname;
        let disable = false;
        const options = this.state.vendorordertype === "Material" ?  this.state.inventorydata.filter((ele, ind) => ind === this.state.inventorydata.findIndex(elem => elem.itemname === ele.itemname && ele.itemname !== "")) : this.state.processData;
        return (
          // <div className="disabled-area my-class" onClick={(e)=>this.onclickevent(e)}>
          <div
            style={{ position: 'relative' }}
          //  onClick={() => $('#tbltemplate td.reset-expansion-style').css('overflow', 'inherit')}
          >
            <div className="more-action single-select-image dropdown coa-select">

              {this.state.inventorydata.length > 0 && <Typeahead

                id={"itemname" + rowIndex + "ddl"}
                inputProps={{
                  className: "rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex + "ddl",
                  title: defaultitemname,
                  //onClick: () => this.hoverOutEllipsis()
                }}
                options={
                  options
                  // this.state.inventorydata.filter((ele, ind) => ind === this.state.inventorydata.findIndex(elem => elem.itemname === ele.itemname && ele.itemname !== ""))

                }

                placeholder={"Enter item Name"}

                // labelKey="label"

                renderMenu={(results: any, menuProps) => (
                  <Menu style={{ zIndex: "-1" }}  {...menuProps}>
                    {results.map((result: any, index: any) => (
                      <>
                        {this.state.inventorydata.length > 0}
                        {result.label !== "" && (
                          <MenuItem
                            option={result}
                            position={index}
                          //        onWheel={() => this.hoverOutEllipsis()}
                          >
                            <EllipsisWithTooltip placement="bottom">
                              {result.label}

                            </EllipsisWithTooltip>
                          </MenuItem>
                        )}

                        {result.itemname === "" && (
                          <MenuItem option={result} position={index}></MenuItem>
                        )}
                      </>
                    ))}
                  </Menu>
                )}

                onChange={this.handleSelectedItem.bind(
                  this,
                  2 + "_" + rowIndex + "_" + defaultitemname
                )}
                selected={row.defaultitemname}
                allowNew
              onBlur={this.handleOutsideClickForAdditionalItems.bind(this, rowIndex)}
              // onKeyDown={this.onTAB.bind(this, "itemname-" + rowIndex.toString() + "ddl", rowIndex)}

              />}

              {/*<button id={btnId} style={{ display: "none", width: "100%" }}
                onClick={() => { this.handleCOAFocus(btnId, id, "rbt-input-main form-control rbt-input itemname" + rowIndex + "ddl", rowIndex); }}
                onFocus={() => { this.handleCOAFocus(btnId, id, "rbt-input-main form-control rbt-input itemname" + rowIndex + "ddl", rowIndex); }} >
                {row.partname === "" && (
                  <EllipsisWithTooltip placement="bottom">{"Enter item name"}</EllipsisWithTooltip>
                )}
                {row.partname !== "" && (
                  <EllipsisWithTooltip placement="bottom">{defaultitemname}</EllipsisWithTooltip>
                )}
              </button> */}
            </div>

            <FaPencilAlt

              onClick={() => this.handleEditClick(rowIndex, row.defaultitemname)}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
              }}
            />

          </div>
        );
      },
    },
    {
      dataField: "JO#",
      text: "JO#",
      headerClasses: "coa",
      // headerStyle: { width: "600px" },
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),

      formatter: (cell: any, row: any, rowIndex: any) => {
        console.log("jo rowww", row);
        let btnId = "itemname" + rowIndex + "btn";
        let id = "itemname" + rowIndex + "ddl";
        const defaultjobno = row.jobNo;

        let disable =  this.state.vendorordertype === "Process" ;

        let JODetails = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.state.JODetails))
        );
        console.log("row JOOOOO", row.JODetails);

        if (!row.JODetails || row.JODetails.length === 0) {
          return (
            <>
            </>
          );
        }

        return (
          <div className="m-1" style={{ width: "350px" }}>
            {row.JODetails && row.JODetails.length > 0 && (
              <DropdownMultiSelect
                key={rowIndex}
                rowIndex={rowIndex}
                data={row.JODetails}
                label="label"
                value="value"
                placeHolder="Select"
                filtertitle="Search..."
                disabled={disable}
                updateMultiSelectItem={(selectedItem) =>
                  this.updateMultiSelectItem(selectedItem, rowIndex, 1)
                }
              />
            )}
          </div>
        );
      },
    },

    // {
    //   dataField: "JO#",
    //   text: "JO#",
    //   formatter: this.LatterCodeFormater,
    //   editable: true,

    //   editorRenderer: (
    //     editorProps,
    //     value,
    //     row,
    //     column,
    //     rowIndex,
    //     columnIndex
    //   ) => (
    //     <div className="department-filter">

    //         <SearchMultiSelectItems
    //           itemsList={this.state.JODetails}
    //           itemsSelected={this.itemsSelected}
    //           selectedItems={row.selectedIDs}
    //           {...editorProps}
    //           value={value}
    //           placeholder={"Search Job"}
    //         />

    //     </div>
    //   ),
    // },
    // {
    //   dataField: "JO#",
    //   text: "JO#",
    //   headerClasses: "coa",

    //   headerStyle: { width: "600px" },
    //   headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),

    //   formatter: (cell: any, row: any, rowIndex: any,value:any) => {
    //     let btnId = "itemname" + rowIndex + "btn";
    //     let id = "itemname" + rowIndex + "ddl";
    //     const defaultjobno = row.jobNo;
    //     let disable = false;

    //     return (
    //       <div className="m-1" style={{width:"350px"}}>
    //         {this.state.JODetails.length > 0 && (
    //       <SearchMultiSelectItems
    //       itemsList={this.state.JODetails}
    //       itemsSelected={this.itemsSelected}
    //       selectedItems={row.selectedIDs}
    //       // {...editorProps}
    //       value={value}
    //       placeholder={"Search Job"}
    //     />
    //     )}
    //     </div>

    //     );
    //   },
    // },


    // {
    //   dataField: "itemDesc",
    //   text: "Item Description",
    //   headerClasses: "coa",

    //   headerStyle: { width: "400px" },
    //   headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
    //   formatter: (cell: any, row: any, rowIndex: any) => {
    //     let id = "txtitemDesc" + rowIndex + "txt";
    //     return (
    //       <div>
    //         <Form.Control
    //           tabIndex={this.state.tabEnable}
    //           id={id}
    //           type="text"
    //           placeholder="Please enter item details"
    //           value={row.itemDesc}
    //           onChange={(e) => this.updateStateAdditionalItems(e, row, "itemDesc", id)}
    //           autoComplete="off"
    //           //pattern="[+-]?\d+(?:[.,]\d+)?"
    //           maxLength={200}
    //           disabled={false}
    //           title=""
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "glcode",
      text: "MS Code",
      headerStyle: { maxwidth: "150px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtglcode" + rowIndex + "txt";
        let disable = false;
        return (
          <div style={{ maxWidth: "150px" }}>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              placeholder="Enter MS Code"
              value={row.glCode}
              onChange={(e) => this.updateStateAdditionalItems(e, row, "glcode", id)}
              autoComplete="off"
              //onKeyDown={(e: any) => this.onTAB("glcode", rowIndex, e)}
              disabled={disable}
              title=""
            />
          </div>
        );
      },
    },
    // {
    //   dataField: "jobNo",
    //   text: "Job No",
    //   headerClasses: "coa",

    //   headerStyle: { width: "400px" },
    //   headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),

    //   formatter: (cell: any, row: any, rowIndex: any) => {
    //     let btnId = "jobno" + rowIndex + "btn";
    //     let id = "jobno" + rowIndex + "ddl";
    //     const defaultitemname = row.selectitem;
    //     let disable = false;
    //     return (
    //       <div>
    //         <div className="more-action single-select-image dropdown coa-select">
    //           {this.state.jobList.length > 0 && <Typeahead

    //             id={"jobno" + rowIndex + "ddl"}
    //             inputProps={{
    //               className: "rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex + "ddl",
    //               title: defaultitemname,
    //               //onClick: () => this.hoverOutEllipsis()
    //             }}
    //             options={
    //               this.state.jobList.filter((ele, ind) => ind === this.state.jobList.findIndex(elem => elem.jobNo === ele.jobNo && ele.jobNo !== ""))
    //             }

    //             placeholder={"Enter Job No"}

    //             // labelKey="label"

    //             renderMenu={(results: any, menuProps) => (
    //               <Menu style={{ zIndex: "-1" }}  {...menuProps}>
    //                 {results.map((result: any, index: any) => (
    //                   <>
    //                     {this.state.jobList.length > 0}
    //                     {result.label !== "" && (
    //                       <MenuItem
    //                         option={result}
    //                         position={index}
    //                       //        onWheel={() => this.hoverOutEllipsis()}
    //                       >
    //                         <EllipsisWithTooltip placement="bottom">
    //                           {result.label}

    //                         </EllipsisWithTooltip>
    //                       </MenuItem>
    //                     )}

    //                     {result.jobNo === "" && (
    //                       <MenuItem option={result} position={index}></MenuItem>
    //                     )}
    //                   </>
    //                 ))}
    //               </Menu>
    //             )}

    //             onChange={this.handleSelectedItem.bind(
    //               this,
    //               3 + "_" + rowIndex + "_" + defaultitemname
    //             )}
    //             selected={row.defaultjobno}

    //           />}

    //         </div>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   dataField: "unit",
    //   text: "Unit",
    //   headerStyle: { width: "100px" },
    //   hidden: false,
    //   editable: false,
    //   headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
    //   formatter: (cell: any, row: any, rowIndex: any) => {
    //     let id = "txtunit" + rowIndex + "txt";
    //     let disable = true;
    //     return (
    //       <div>
    //         <Form.Control
    //           tabIndex={this.state.tabEnable}
    //           id={id}
    //           type="text"
    //           // placeholder="Enter Qty"
    //           value={row.unit}
    //           onChange={(e) => this.updateStateAdditionalItems(e, row, "unit", id)}
    //           autoComplete="off"
    //           pattern="[+-]?\d+(?:[.,]\d+)?"
    //           maxLength={20}
    //           disabled={false}
    //           title=""
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "qty",
      text: "Qty",
      headerStyle: { width: "250px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        console.log("job qty", row);
        let id = "txtreqqty" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={row.qty}
              onChange={(e) => this.updateStateAdditionalItems(e, row, "qty", id)}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={false}
              title=""
            />
          </div>
        );
      },
    },

    {
      dataField: "unitPrice",
      text: "Unit Price",
      headerStyle: { width: "100px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtunitPrice" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={row.unitPrice}
              onChange={(e) => this.updateStateAdditionalItems(e, row, "unitPrice", id)}
              onBlur={(e: any) => this.amountBlurAdditionalItems(e, rowIndex, "unitPrice", row)}
              onFocus={(e: any) => this.onAmountFocusAdditionalItems(e, rowIndex, id, "unitPrice")}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={false}
              title=""
            />
          </div>
        );
      },
    },

    {
      dataField: "amount",
      text: "Amount",

      headerStyle: { width: "150px" },
      hidden: false,
      editable: false,
      headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let id = "txtamount" + rowIndex + "txt";
        let disable = true;
        return (
          <div>
            <Form.Control
              tabIndex={this.state.tabEnable}
              id={id}
              type="text"
              // placeholder="Enter Qty"
              value={Utils.currencyFormat(Number(Utils.removeInvaildCharFromAmount(row.unitPrice)) * row.qty)}
              onChange={(e) => this.updateStateAdditionalItems(e, row, "amount", id)}
              autoComplete="off"
              pattern="[+-]?\d+(?:[.,]\d+)?"
              maxLength={20}
              disabled={disable}
              title=""
            />
          </div>
        );
      },
    },
    {
      dataField: 'Attachment',
      text: '',
      //headerFormatter: this.iconFormatter,
      headerAttrs: (column, colIndex) => (
        { 'tabIndex': '-1' }
      ),
      formatter: (cell: any, row: any, rowIndex: any) => {
        let itemDetailConfirmModalContent = [
          {
            title: "Delete Row",
            desc: "Are you sure you want to delete?",
            cancleAction: "Cancel",
            ActionTrue: "Delete",
          },
        ];
        // if (this.state.additionalMaterialItems.length >= 0) {
        //   return (
        //     <div></div>
        //   );
        // }
        // else {
        return (
          <Dropdown className="more-action confirmation-modal" tabIndex={this.state.tabDisable}>
            <Dropdown.Toggle className="btn-outline-primary btn btn-primary more fa-trash" id="dropdown-more-delete" tabIndex={this.state.tabDisable}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 5.83329C4.53917 5.83329 4.16667 5.83329 4.16667 5.83329V16.6666C4.16667 17.5866 4.91333 18.3333 5.83333 18.3333H14.1667C15.0867 18.3333 15.8333 17.5866 15.8333 16.6666V5.83329C15.8333 5.83329 15.4608 5.83329 15 5.83329H5ZM8.33333 15.8333H6.66667V8.33329H8.33333V15.8333ZM13.3333 15.8333H11.6667V8.33329H13.3333V15.8333ZM13.8483 3.33329L12.5 1.66663H7.5L6.15167 3.33329H2.5V4.99996H6.66667H13.3333H17.5V3.33329H13.8483Z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <ConfirmationModal
                confirmModalContent={itemDetailConfirmModalContent}
                ishandleDeletePosition={this.handleAdditionalItemsDeleteRow.bind(
                  this,
                  row
                )}
              />
            </Dropdown.Menu>
          </Dropdown>
        );
        // }
      },
    },

  ];


  constructor(props: any) {
    super(props);
    this.parentScroll = React.createRef();
    this.customerSearch = React.createRef();
    this.trDate = React.createRef();
    this.handleVendorChange = this.handleVendorChange.bind(this);
    this.GetCustomerItems = this.GetCustomerItems.bind(this);

    this.ChildComment = React.createRef();
    templateColumns: this.templateColumns,
      this.state = {
        showOptionSelect: this.props.vendororderid == 0 ? true : false,
        CODetails: [],
        JODetails: [],
        optionss: [],
        processData: [],
        isApproved: false,
        invoiceCreated: false,
        showMailPanel: false,
        ship_via: "",
        showAfterSaveModelPopup: false,
        showCustomerSlideout: false,
        showJobSlideout: false,
        editMode: false,
        mailContent: {
          MailTo: '',
          CC: '',
          Message: ''
        },
        CustomerPoNumber: this.props.CustomerPoNumber,
        id: this.props.id,
        productid: this.props.productid,
        partname: this.props.partname,
        partNo: this.props.partNo,
        qtyOrdered: this.props.qtyOrdered,
        itemNo: this.props.itemNo,
        poNumber: this.props.poNumber,
        dueDate: this.props.dueDate,
        jobDesc: this.props.jobDesc,
        selectedCustomer: this.props.selectedCustomer,
        orderdate: this.props.orderdate,
        tabEnable: 0,
        vendororderid: this.props.vendororderid,
        vendorordertype: this.props.vendorordertype,
        orderid: this.props.orderid,
        orderdetailid: 0,
        jobid: 0,
        address: this.props.address,
        customercode: this.props.customercode,
        customerNameLatest: this.props.selectedCustomer,
        jobOrderlistByItem: [],
        misclistByItem: [{
          JODetails: [],
          defaultitemname: [],
          srno: 1,
          itemDesc: '',
          itemNo: "1",
          id: 0,
          glCode: "",
          itemName: "",
          qty: 0,
          unitPrice: Utils.currencyFormat(0),
          unit: "",
          jobNo: "",
        }],
        Defaultselected: [],
        options: [],
        additionalMaterialItems: [
          {
            JODetails: [],
            defaultitemname: [],
            defaultjobno: [],
            defaultcustomerPoNumber: [],
            srno: 1,
            itemDesc: '',
            itemNo: "1",
            id: 0,
            glCode: "",
            itemName: "",
            qty: 0,
            unitPrice: Utils.currencyFormat(0),
            unit: "",
            jobNo: "",
            jobid: 0,

          }
        ],

        orderlistitem: [],
        transactionsColumns: this.transactionsColumns,
        templateColumns: this.templateColumns,
        itemColumns: this.itemColumns,
        AdditionalMaterialItemColumns: this.AdditionalMaterialItemColumns,
        totalCount: 0,
        currentZoom: 0.7,

        orderData: [
          {
            defaultPartname: [],
            id: 0,
            orderID: 0,
            itemNo: 1,
            partname: "",
            partNo: "",
            dueDate: "",
            jobDesc: "",
            qtyOrdered: 0,
            unit: "",
            unitPrice: Utils.currencyFormat(0),
            discount: 0,
            total: 0,
            tenantid: 0,
            productid: 0,

          },
          // ,
          // {
          //     // defaultPartname: [],
          //     id: 0, orderID: 0, itemNo: 2, partname: '', partNo: '', dueDate: '', jobDesc: '', qtyOrdered: 0, unit: '', unitPrice: 0, discount: 0, total: 0, tenantid: 0, productid: 0,
          // }
        ],

        jobdetailsdata: [],

        formData: {
          OrderID: 0,
          CustomerID: 0,
          POInitiated: false,
          CustomerName: "",
          CustomerPoNumber: "",
          shippingInstruction: "",
          OrderDate: new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime)),
          duedate: new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime)),
          ship_via: "",
          Address: "",
          firstname: "",
          VendorOrderType: "normal",
          customercode: "",
          IsEdit: "",
          OrderItems: [],
          Vendor3PartyOrderItems: [],
          VendorOrderItemDetails: [],
          ReceivingData: [],
        },
        fileSize: 5242880,
        itemListdatam: [],
        inventorydata: [],
        jobList: [],
        defaultitemname: [],
        itemdetailsdata: [
          {
            defaultitemname: [],
            itemNo: "1",
            id: 0,
            itemname: "",
            glcode: "",
            description: "",
            itemid: 0,
            stockqty: 0,
            qty: 0,
            orderqty: 0,
            unitPrice: Utils.currencyFormat(0),
            unit: "",
          },

        ],
        itemdetailsdatanew: [
          {
            defaultitemname: [],
            srno: 1,
            itemDesc: '',
            itemNo: "1",
            id: 0,
            glCode: "",
            itemName: "",
            qty: 0,
            unitPrice: Utils.currencyFormat(0),
            unit: "",
          },

        ],
      };
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.updatedCommentItem = this.updatedCommentItem.bind(this);
  }

  handleChangeBuyer = (event: any) => {

    let Defaultselected: any = [];
    let formData = { ...this.state.formData };
    if (event.length > 0) {


      let objData: any = {};

      objData.id = event[0].id;
      objData.name = event[0].name;
      Defaultselected.push(objData);

      formData.firstname = event[0].name;
    }

    this.setState({ Defaultselected: Defaultselected, formData: formData, isStateChanged: true });

  };

  updateState = (e: any, row: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;



      let jobOrderlistByItem = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.jobOrderlistByItem))
      );

      let misclistByItem = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.misclistByItem))
      );



      if (type === "itemDesc") {
        e.preventDefault();

        let itemRows = jobOrderlistByItem.filter((x) => x.rowId == row.rowId);
        if (itemRows != null && itemRows.length > 0) {
          itemRows.forEach(element => {
            element.itemDesc = e.target.value;
          });
          //jobOrderlistByItem[index].itemName = e.target.value;
        }

        this.setState({ jobOrderlistByItem: jobOrderlistByItem, isStateChanged }, () => {


          $("#tblOrder")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblOrder").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "glcode") {
        e.preventDefault();

        let itemRows = jobOrderlistByItem.filter((x) => x.rowId == row.rowId);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].glCode = e.target.value;
        }

        this.setState({ jobOrderlistByItem: jobOrderlistByItem, isStateChanged }, () => {


          $("#tblOrder")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblOrder").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "unitPrice") {
        e.preventDefault();

        let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        e.target.value = newVal;

        let itemRows = jobOrderlistByItem.filter((x) => x.rowId == row.rowId);
        if (itemRows != null && itemRows.length > 0) {
          itemRows.forEach(element => {
            element.unitPrice = e.target.value;
          });

        }



        this.setState({ jobOrderlistByItem: jobOrderlistByItem, isStateChanged }, () => {
          $("#tblOrder")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblOrder").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "qty") {
        e.preventDefault();

        let newVal = e.target.value.replace(/[^0-9\s]/g, '');
        e.target.value = newVal;

        // let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        // e.target.value = newVal;

        let itemRows = jobOrderlistByItem.filter((x) => x.rowId == row.rowId);
        if (itemRows != null && itemRows.length > 0) {
          itemRows.forEach(element => {
            element.qty = e.target.value;
          });
          //        jobOrderlistByItem[index].qty = e.target.value;

        }



        this.setState({ jobOrderlistByItem: jobOrderlistByItem, isStateChanged }, () => {
          $("#tblOrder")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblOrder").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "unit") {
        e.preventDefault();

        let newVal = e.target.value.replace(/[^0-9\s]/g, '');
        e.target.value = newVal;

        // let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        // e.target.value = newVal;

        let itemRows = jobOrderlistByItem.filter((x) => x.rowId == row.rowId);
        if (itemRows != null && itemRows.length > 0) {
          itemRows.forEach(element => {
            element.unit = e.target.value;
          });
          //        jobOrderlistByItem[index].qty = e.target.value;

        }



        this.setState({ jobOrderlistByItem: jobOrderlistByItem, isStateChanged }, () => {
          $("#tblOrder")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblOrder").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "expandqty") {
        e.preventDefault();

        let newVal = e.target.value.replace(/[^0-9\s]/g, '');
        e.target.value = newVal;

        // let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        // e.target.value = newVal;

        let itemRows = jobOrderlistByItem.filter((x) => x.rowId == row.rowId && x.rowType == 2);
        if (itemRows != null && itemRows.length > 0) {
          itemRows.forEach(element => {
            element.qty = e.target.value;
          });
          //        jobOrderlistByItem[index].qty = e.target.value;

        }



        this.setState({ jobOrderlistByItem: jobOrderlistByItem, isStateChanged }, () => {
          $("#tblOrder")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblOrder").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
    }
  };


  amountBlur = (e, rowIndex, type: any, row: any) => {

    let jobOrderlistByItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobOrderlistByItem))
    );

    if (e.target.value.includes(".")) {
      const timeArray = e.target.value?.split(".");
      if (timeArray[1].length <= 0) {
        if (type === "unitPrice") {
          let itemRows = jobOrderlistByItem.filter((x) => x.rowId == row.rowId);

          if (itemRows != null && itemRows.length > 0) {
            itemRows.forEach(element => {
              element.unitPrice = Utils.currencyFormat(
                timeArray[0]
              );
            });

          }
        }
      } else {
        if (type === "unitPrice") {
          let itemRows = jobOrderlistByItem.filter((x) => x.rowId == row.rowId);

          if (itemRows != null && itemRows.length > 0) {
            itemRows.forEach(element => {
              element.unitPrice = Utils.currencyFormat(
                e.target.value
              );
            });

          }
        }


      }
      //this.setState({ jobOrderlistByItem });
      this.setState({ jobOrderlistByItem: jobOrderlistByItem }, () => { });
    } else {
      if (type === "unitPrice") {
        let itemRows = jobOrderlistByItem.filter((x) => x.rowId == row.rowId);

        if (itemRows != null && itemRows.length > 0) {
          itemRows.forEach(element => {
            element.unitPrice = e.target.value === ""
              ? ""
              : !isNaN(e.target.value)
                ? Utils.currencyFormat(e.target.value)
                : e.target.value;
          });

        }
      }

      this.setState({ jobOrderlistByItem: jobOrderlistByItem }, () => { });
    }
  };

  onAmountFocus = (e, index, txtID, type: any) => {

    let jobOrderlistByItem = Object.assign([], JSON.parse(JSON.stringify(this.state.jobOrderlistByItem)));
    //let newval = Utils.removecurrencyFormat(e.target.value);
    let start = e.target?.selectionStart;
    let end = e.target?.selectionEnd;
    if (type === "unitPrice") {
      let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
      e.target.value = newVal;
      jobOrderlistByItem[index].unitPrice = e.target.value;

      this.setState({ jobOrderlistByItem }, () => {
        $("#tblOrder")
          .find("input[id^='" + txtID + "']")
          .focus();
        let txtdesc = $("#tblOrder").find(
          "input[id^='" + txtID + "']"
        )[0] as any;
        if (txtdesc !== null && txtdesc !== undefined) {
          txtdesc.selectionStart = start;
          txtdesc.selectionEnd = end;
        }
      });
    }

    if (type === "discount") {
      let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
      e.target.value = newVal;
      jobOrderlistByItem[index].discount = e.target.value;

      this.setState({ jobOrderlistByItem }, () => {
        $("#tblOrder")
          .find("input[id^='" + txtID + "']")
          .focus();
        let txtdesc = $("#tblOrder").find(
          "input[id^='" + txtID + "']"
        )[0] as any;
        if (txtdesc !== null && txtdesc !== undefined) {
          txtdesc.selectionStart = start;
          txtdesc.selectionEnd = end;
        }
      });
    }


  };

  updateStateMisc = (e: any, row: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;


      let misclistByItem = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.misclistByItem))
      );



      if (type === "itemDescForMisc") {
        e.preventDefault();
        let itemRows = misclistByItem.filter((x) => x.srno == row.srno);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].itemDesc = e.target.value;
        }

        this.setState({ misclistByItem: misclistByItem, isStateChanged }, () => {


          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "glcode") {
        e.preventDefault();

        let itemRows = misclistByItem.filter((x) => x.srno == row.srno);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].glCode = e.target.value;
        }

        this.setState({ misclistByItem: misclistByItem, isStateChanged }, () => {


          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "unitPrice") {
        e.preventDefault();

        let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        e.target.value = newVal;

        let itemRows = misclistByItem.filter((x) => x.srno == row.srno);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].unitPrice = e.target.value;
        }



        this.setState({ misclistByItem: misclistByItem, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "qty") {
        e.preventDefault();

        let newVal = e.target.value.replace(/[^0-9\s]/g, '');
        e.target.value = newVal;
        // let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        // e.target.value = newVal;

        let itemRows = misclistByItem.filter((x) => x.srno == row.srno);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].qty = e.target.value;
        }



        this.setState({ misclistByItem: misclistByItem, isStateChanged }, () => {
          $("#tbltemplate")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tbltemplate").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
    }
  };

  amountBlurMisc = (e, rowIndex, type: any, row: any) => {

    let misclistByItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.misclistByItem))
    );

    if (e.target.value.includes(".")) {
      const timeArray = e.target.value?.split(".");
      if (timeArray[1].length <= 0) {
        if (type === "unitPrice") {

          let itemRows = misclistByItem.filter((x) => x.srno == row.srno);
          if (itemRows != null && itemRows.length > 0) {
            itemRows[0].unitPrice = Utils.currencyFormat(
              timeArray[0]
            );
          }
        }
      } else {
        if (type === "unitPrice") {



          let itemRows = misclistByItem.filter((x) => x.srno == row.srno);
          if (itemRows != null && itemRows.length > 0) {
            itemRows[0].unitPrice = Utils.currencyFormat(
              e.target.value
            );
          }
        }


      }
      //this.setState({ misclistByItem });
      this.setState({ misclistByItem: misclistByItem }, () => { });
    } else {
      if (type === "unitPrice") {

        let itemRows = misclistByItem.filter((x) => x.srno == row.srno);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].unitPrice = e.target.value === ""
            ? ""
            : !isNaN(e.target.value)
              ? Utils.currencyFormat(e.target.value)
              : e.target.value;
        }
      }

      this.setState({ misclistByItem: misclistByItem }, () => { });
    }
  };

    handleType = (selectedId: any) => {
      const masg = "You have unsaved data. Are you sure you want to leave this tab? Your current data will be reset.";
      if (
        (this.state.additionalMaterialItems.length === 1 && this.state.additionalMaterialItems[0].id === 0 && this.state.additionalMaterialItems[0].defaultitemname.length === 0)
        // ||
        // (this.state.misclistByItem.length === 1 && this.state.misclistByItem[0].id === 0 && this.state.misclistByItem[0].defaultitemname.length === 0)
      ) {
        this.setState({ vendorordertype: selectedId, showSection: false });
      }
      else {
        confirmAlert({
          title: "Unsaved Data !",
          message: masg,
          buttons: [
            {
              label: "Confirm",
              onClick: () => {
                let JODetails1 = Object.assign([], JSON.parse(JSON.stringify(this.state.JODetails)));
                const additionalMaterialItems = [{
                  defaultitemname: [],
                  defaultjobno: [],
                  defaultcustomerPoNumber: [],
                  srno: 1,
                  itemDesc: '',
                  itemNo: "1",
                  id: 0,
                  glCode: "",
                  itemName: "",
                  qty: 0,
                  unitPrice: Utils.currencyFormat(0),
                  unit: 0,
                  jobNo: "",
                  jobid: 0,
                  JODetails:JODetails1
                }];

            

                this.setState({ vendorordertype: selectedId, additionalMaterialItems, showSection: false });
              },
            },
            {
              label: "Discard",
              onClick: () => reject(),
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    };



  onAmountFocusMisc = (e, index, txtID, type: any) => {


    let misclistByItem = Object.assign([], JSON.parse(JSON.stringify(this.state.misclistByItem)));
    //let newval = Utils.removecurrencyFormat(e.target.value);
    let start = e.target?.selectionStart;
    let end = e.target?.selectionEnd;
    if (type === "unitPrice") {
      let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
      e.target.value = newVal;
      misclistByItem[index].unitPrice = e.target.value;

      this.setState({ misclistByItem }, () => {
        $("#tbltemplate")
          .find("input[id^='" + txtID + "']")
          .focus();
        let txtdesc = $("#tbltemplate").find(
          "input[id^='" + txtID + "']"
        )[0] as any;
        if (txtdesc !== null && txtdesc !== undefined) {
          txtdesc.selectionStart = start;
          txtdesc.selectionEnd = end;
        }
      });
    }
  };

  updateStateAdditionalItems = (e: any, row: any, type: any, txtID) => {
    if (this != undefined) {
      let isStateChanged = true;
      let start = e.target?.selectionStart;
      let end = e.target?.selectionEnd;


      let additionalMaterialItems = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.additionalMaterialItems))
      );



      if (type === "itemDesc") {
        e.preventDefault();
        let itemRows = additionalMaterialItems.filter((x) => x.srno == row.srno);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].itemDesc = e.target.value;
        }

        this.setState({ additionalMaterialItems: additionalMaterialItems, isStateChanged }, () => {


          $("#tblAdditionalMaterialItems")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblAdditionalMaterialItems").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "glcode") {
        e.preventDefault();
        let itemRows = additionalMaterialItems.filter((x) => x.srno == row.srno);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].glCode = e.target.value;
        }

        this.setState({ additionalMaterialItems: additionalMaterialItems, isStateChanged }, () => {


          $("#tblAdditionalMaterialItems")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblAdditionalMaterialItems").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "unitPrice") {
        e.preventDefault();

        let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        e.target.value = newVal;

        let itemRows = additionalMaterialItems.filter((x) => x.srno == row.srno);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].unitPrice = e.target.value;
        }



        this.setState({ additionalMaterialItems: additionalMaterialItems, isStateChanged }, () => {
          $("#tblAdditionalMaterialItems")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblAdditionalMaterialItems").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "qty") {
        e.preventDefault();
        let newVal = e.target.value.replace(/[^0-9\s]/g, '');
        e.target.value = newVal;
        //let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        // e.target.value = newVal;

        let itemRows = additionalMaterialItems.filter((x) => x.srno == row.srno);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].qty = e.target.value;
        }



        this.setState({ additionalMaterialItems: additionalMaterialItems, isStateChanged }, () => {
          $("#tblAdditionalMaterialItems")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblAdditionalMaterialItems").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
      else if (type === "unit") {
        e.preventDefault();
        let newVal = e.target.value.replace(/[^0-9\s]/g, '');
        e.target.value = newVal;
        //let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
        // e.target.value = newVal;

        let itemRows = additionalMaterialItems.filter((x) => x.srno == row.srno);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].unit = e.target.value;
        }



        this.setState({ additionalMaterialItems: additionalMaterialItems, isStateChanged }, () => {
          $("#tblAdditionalMaterialItems")
            .find("input[id^='" + txtID + "']")
            .focus();
          let txtdesc = $("#tblAdditionalMaterialItems").find(
            "input[id^='" + txtID + "']"
          )[0] as any;
          if (txtdesc !== null && txtdesc !== undefined) {
            txtdesc.selectionStart = start;
            txtdesc.selectionEnd = end;
          }
        });
      }
    }
  };

  amountBlurAdditionalItems = (e, rowIndex, type: any, row: any) => {

    let additionalMaterialItems = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.additionalMaterialItems))
    );

    if (e.target.value.includes(".")) {
      const timeArray = e.target.value?.split(".");
      if (timeArray[1].length <= 0) {
        if (type === "unitPrice") {

          let itemRows = additionalMaterialItems.filter((x) => x.srno == row.srno);
          if (itemRows != null && itemRows.length > 0) {
            itemRows[0].unitPrice = Utils.currencyFormat(
              timeArray[0]
            );
          }
        }
      } else {
        if (type === "unitPrice") {



          let itemRows = additionalMaterialItems.filter((x) => x.srno == row.srno);
          if (itemRows != null && itemRows.length > 0) {
            itemRows[0].unitPrice = Utils.currencyFormat(
              e.target.value
            );
          }
        }


      }
      //this.setState({ additionalMaterialItems });
      this.setState({ additionalMaterialItems: additionalMaterialItems }, () => { });
    } else {
      if (type === "unitPrice") {

        let itemRows = additionalMaterialItems.filter((x) => x.srno == row.srno);
        if (itemRows != null && itemRows.length > 0) {
          itemRows[0].unitPrice = e.target.value === ""
            ? ""
            : !isNaN(e.target.value)
              ? Utils.currencyFormat(e.target.value)
              : e.target.value;
        }
      }

      this.setState({ additionalMaterialItems: additionalMaterialItems }, () => { });
    }
  };


  updateMultiSelectItem = (selectedItem: any, rowIndex: any, controlId: any) => {

    let additionalMaterialItems = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.additionalMaterialItems))
    );
    let misclistByItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.misclistByItem))
    );
    let JODetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.JODetails))
    );

    if (controlId === 1) {
      console.log("slected job handle", selectedItem, additionalMaterialItems[rowIndex]);
      additionalMaterialItems[rowIndex].defaultjobno = selectedItem;
      const jobIds = selectedItem.map(item => item.jobid).join(',');
      const labels = selectedItem.map(item => item.label).join(',');
      const selectedids = selectedItem.map((item, index) => ({
        id: index,
        jobid: item.jobid,
        jobno: item.value
      }));

      selectedItem.forEach(selectedElement => {
        JODetails.forEach(jobDetail => {
          if (jobDetail.jobid === selectedElement.jobid) {
            jobDetail.isChecked = true;
          }
        });
      });

      additionalMaterialItems[rowIndex].JODetails = JODetails;
      additionalMaterialItems[rowIndex].selectedIDs = selectedids;
      additionalMaterialItems[rowIndex].jobNo = labels;
      additionalMaterialItems[rowIndex].jobId = jobIds;
    } else {
      misclistByItem[rowIndex].defaultjobno = selectedItem;
      const jobIds = selectedItem.map(item => item.jobid).join(',');
      const labels = selectedItem.map(item => item.label).join(',');
      const selectedids = selectedItem.map((item, index) => ({
        id: index,
        jobid: item.jobid,
        jobno: item.value
      }));
      selectedItem.forEach(selectedElement => {
        JODetails.forEach(jobDetail => {
          if (jobDetail.jobid === selectedElement.jobid) {
            jobDetail.isChecked = true;
          }
        });
      });
      misclistByItem[rowIndex].JODetails = JODetails;
      misclistByItem[rowIndex].selectedIDs = selectedids;
      console.log("selexted 2", misclistByItem[rowIndex], selectedids);

      misclistByItem[rowIndex].jobNo = labels;
      misclistByItem[rowIndex].jobId = jobIds;

    }
    this.setState(
      { additionalMaterialItems, misclistByItem, isStateChanged: true },
      () => {

      });

  };

  onAmountFocusAdditionalItems = (e, index, txtID, type: any) => {


    let additionalMaterialItems = Object.assign([], JSON.parse(JSON.stringify(this.state.additionalMaterialItems)));
    //let newval = Utils.removecurrencyFormat(e.target.value);
    let start = e.target?.selectionStart;
    let end = e.target?.selectionEnd;
    if (type === "unitPrice") {
      let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
      e.target.value = newVal;
      additionalMaterialItems[index].unitPrice = e.target.value;

      this.setState({ additionalMaterialItems }, () => {
        $("#tblAdditionalMaterialItems")
          .find("input[id^='" + txtID + "']")
          .focus();
        let txtdesc = $("#tblAdditionalMaterialItems").find(
          "input[id^='" + txtID + "']"
        )[0] as any;
        if (txtdesc !== null && txtdesc !== undefined) {
          txtdesc.selectionStart = start;
          txtdesc.selectionEnd = end;
        }
      });
    }
  };



  scrollToBottom = () => {
    this.parentScroll.current.scrollTo({
      //top: this.parentScroll.current.clientHeight,
      top: this.parentScroll.current.scrollHeight,
      behavior: "smooth",
    });
  };
  updatedCommentItem = () => {
    let updatedItem: any = {};
    this.setState({ isSave: true });
    updatedItem.transcationDate = this.state.formData.transcationDate;
    updatedItem.vendorName = this.state.formData.vendorName;
    updatedItem.amountTotal = this.state.totalAmount;
    return updatedItem;
  };
  CommentModalClose = () => {

    let uniqueno = this.state.formData.uniqueno;
    this.handleDiscard(uniqueno);
  };

  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  closeCustomerSlideout = () => {
    this.setState({ showCustomerSlideout: false, isSpinner: false, });
    this.onLoadData();
    this.GetorderEntryColoumn();
  };

  closeJobSlideout = () => {
    this.setState({ showJobSlideout: false, isSpinner: false, });
    this.onLoadData();
    this.GetorderEntryColoumn();
  };

  closeSlideOut2 = () => {
    this.setState({ showjobrModal: false, isSpinner: false, });
  };





  beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
    if (
      this.state.isStateChanged &&
      routeLeavingGuard !== "routeLeavingGuardTrue"
    ) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  routeLeavingGuardConfirm = (childState: any) => {
    if (childState === true) {
      routeLeavingGuard = "routeLeavingGuardTrue";
    } else {
      routeLeavingGuard = null;
    }
  };

  componentDidMount() {
    this.GetJoblist();
    this.GetItemlist();
    this.getJoblist();
    this.onLoadData();
    this.GetorderEntryColoumn();
    this.GetCustomerItems();
    this.getprocessMaster();

    // if (this.customerSearch.current) {
    //   this.customerSearch.current.focus();
    // }

  }

  getprocessMaster = () => {
    PartTemplateService.GetProcessListAll({})
      .then(async (result: any[] | null) => {
        if (result != null) {
          console.log("process data", result);
          
          const filteredData = result.filter(item => item.isFixed === 1);
          const formattedData = filteredData.map(item => ({
            description: item.pDescription,  
            id: item.id,
            itemname: `${item.processName}\n${item.pDescription}`,
            // itemname: item.processName,
            label:  item.processName,
            selectitem: item.processName,
            tenantid:item.tenantid,
            glcode:"",
            qty:0,
            stockqty: 0,
            unit: "",
            unitPrice: 0
          }));
  
          this.setState({ processData: formattedData });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        this.setState({ isWait: false });
        reject();
      });
  };
  



  GetJoblistById(orderid: any) {
    let saveRequest: any = {};
    saveRequest.orderid = orderid;
    saveRequest.tenantid = 0;

    CustomerService.Getorderdataoptimize(saveRequest)
      .then(async (result) => {
        if (result != null) {
          this.setState({ JODetails: [] }, () => {
            const items = result.customerOrderDetailsToUpdateList.map(item => ({
              label: item.jobno,
              id: item.jobno,
              value: item.jobno,
              jobid: item.jobid,
              isChecked: false
            }));


            // this.setState({
            //   JODetails: items,
            // }, () => {
            //   console.log("Updated JODetails in state", this.state.JODetails);
            // });
          });
        }
        resolve();
      })
      .catch((err) => {
        toast.error(`Server Error, ${err}`);
        reject();  // Reject promise if you're using promises (ensure `reject` is defined)
      });
  }

  GetJoblist = () => {
    JobServices.GetJoblist({})
      .then(async (result: any[] | null) => {
        if (result != null) {
          result.forEach((element) => {
            let item: any = {};
            item.value = element.jobNo;
            item.label = element.jobNo;
            item.jobid = element.jobid;
            item.isChecked = false;
            this.state.JODetails.push(item);
          });

          if (this.state.vendororderid === 0 || this.state.vendororderid == undefined) {
            let JODetails1 = Object.assign([], JSON.parse(JSON.stringify(this.state.JODetails)));
            let JODetails2 = Object.assign([], JSON.parse(JSON.stringify(this.state.JODetails)));

            const newItems = [...this.state.additionalMaterialItems];
            newItems[0] = {
              ...newItems[0],
              JODetails: JODetails1
            };

            const newItemss = [...this.state.misclistByItem];
            newItemss[0] = {
              ...newItemss[0],
              JODetails: JODetails2
            };

            this.setState({ additionalMaterialItems: newItems, misclistByItem: newItemss });
          }


        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  };

  GetCustomerItems = () => {
    CustomerService.GetCustomerOrder()
      .then(async (result: any[] | null) => {
        if (result != null) {
          const items = result.map(item => ({
            label: item.customerPoNumber,
            id: item.customerPoNumber,
            orderid: item.orderID
          }));
          console.log("cust items------", items);
          this.setState({
            CODetails: items
          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        reject();
      });
  };

  GetItemlist = () => {

    let requestlist = { ...this.state.requestlist };
    let inventorydata: any = [];

    let TypeInventory: any = [];

    inventorydata = Object.assign([], JSON.parse(JSON.stringify(this.state.inventorydata)));

    JobServices.GetInventoryData(requestlist)
      .then(async (resultinv: any | null) => {

        if (resultinv !== null) {
          resultinv.forEach(element => {

            let value = element.product_name + (element.inventory_description != null && element.inventory_description.length > 0 ? " ( " + element.inventory_description + " )" : "");
            let item: any = {};
            item.id = element.product_id;
            item.itemname = value;
            item.label = value;
            item.selectitem = element.product_name;
            item.glcode = element.glcode;
            item.stockqty = element.stockqty,
              // + "-" + element.category_name + "-" + element.producttype_name + " (" + element.sizedesc + " )";
              item.description = element.inventory_description;
            item.qty = element.quantity_in_stock;
            item.unitPrice = 0;
            item.unit = element.unit;
            TypeInventory.push(item);

          });
          console.log("oooooooooo",TypeInventory);
          this.setState({ inventorydata: TypeInventory });

        }
        resolve();

      })
      .catch((error) => {

        reject();
      });

  };

  getJoblist = () => {

    let requestlist = { ...this.state.requestlist };
    JobServices.GetJoblist(requestlist)
      .then(async (resultinv: any | null) => {

        if (resultinv !== null) {
          resultinv.forEach(element => {
            element.label = element.jobNo;
          });
          this.setState({ jobList: resultinv });

        }
        resolve();

      })
      .catch((error) => {

        reject();
      });

  };


  getOptions = (requestlist: any, id: any) => {
    VendorService.GetVendorlist(requestlist)
      .then(async (result: any | null) => {

        if (result !== null) {
          const matchedRecord = result.find((record: any) => record.vendor_id === id);

          if (matchedRecord && matchedRecord.vendorContact) {
            const options: any[] = [];
            matchedRecord.vendorContact.forEach((element: any) => {
              let item: any = {};
              item.id = element.id;
              item.name = element.firstname;
              options.push(item);
            });
            this.setState({ options: options });

          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };


  GetorderEntryColoumn = () => {
    let request: any = {};
    let requestlist: any = {};
    request.orderid = this.state.vendororderid;
    request.tenantid = 0;
    request.vendorordertype = this.state.vendorordertype;
    request.jobid = this.props.jobid;

    let orderData: any = [];
    VendorService.Getorderdata(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          console.log(result);


          if (result.vendorOrderToUpdateList.length > 0) {

            let options: any = [];

            result.vendorContact.forEach(element => {

              let item: any = {};
              item.id = element.id;
              item.name = element.firstname;
              options.push(item);

            });
            let custommerSelected: any = [];
            let mainArray = result.vendorOrderToUpdateList[0];
            let formData = { ...this.state.formData };
            formData.OrderID = mainArray.orderID;
            formData.CustomerID = mainArray.vendorID;
            formData.CustomerName = mainArray.vendorName;
            formData.customercode = mainArray.vendorcode;
            formData.CustomerPoNumber = mainArray.vendorPoNumber;
            formData.ship_via = mainArray.ship_via;
            formData.OrderDate = mainArray.orderDate;
            formData.duedate = mainArray.dueDate;
            formData.POInitiated = mainArray.poInitiated;
            let isApproved = mainArray.poInitiated == true ? false : true;
            formData.Address = mainArray.address;
            formData.firstname = mainArray.contactName == null ? "" : mainArray.contactName;
            custommerSelected.company_name = mainArray.vendorName;
            custommerSelected.address = mainArray.address;
            custommerSelected.customer_id = mainArray.vendorID;
            custommerSelected.customercode = mainArray.vendorcode;

            if (mainArray.vendorID) this.getOptions(requestlist, mainArray.vendorID);

            let Defaultselected: any = [];
            let objData: any = {};

            objData.id = mainArray.contactName;
            objData.name = mainArray.contactName == null ? "" : mainArray.contactName;
            Defaultselected.push(objData);

            this.customerSearch?.current.handleSelectFromParent(
              custommerSelected
            );
            this.state.mailContent.MailTo = mainArray.vendorEmail;
            this.setState(
              {
                isApproved: isApproved,
                ship_via: formData.ship_via,
                orderData: orderData,
                formData: formData,
                Defaultselected: Defaultselected,
                productid: 0,
                isSpinner: false,
              },
              () => { }
            );
          }

          let jobdetails: any = [];
          console.log(" result.vendor3PartyOrderDetailList", result.vendor3PartyOrderDetailList);
          if (result.vendor3PartyOrderDetailList.length > 0) {
            jobdetails = result.vendor3PartyOrderDetailList;

            jobdetails.forEach((element, idx) => {
              element.unitPrice = Utils.currencyFormat(element.unitPrice);
            });
            let job = jobdetails.filter((x) => x.selected == true);
            if (job && job.length > 0) {
              this.state.mailContent.Message = "Please quote " + job[0].jdescription
                + "\n\n< ADD ADDITIONAL CONTENT HERE > \n\n\n\n\n\nThanks & Regards,\n" + result.vendorOrderToUpdateList[0].companyName;

            }
          }

          if (result.vendorOrderDetailsToUpdateList.length > 0) {
            let job = result.vendorOrderDetailsToUpdateList.filter((x) => x.selected == true);
            if (job && job.length > 0) {

              const qtys = job.map(obj => obj.qty);
              const totalSum = qtys.reduce((acc, curr) => acc + curr, 0);
              this.state.mailContent.Message = "Please quote " + job[0].itemName + ', ' + totalSum + 'pc.'
                + "\n\n< ADD ADDITIONAL CONTENT HERE > \n\n\n\n\n\nThanks & Regards,\n" + result.vendorOrderToUpdateList[0].companyName;
            }
          }
          let misclistByItem = this.state.misclistByItem;
          if (this.state.vendorordertype == 'Miscellaneous' && result.vendorOrderItemDetails.length > 0) {
            misclistByItem = [];
            result.vendorOrderItemDetails.forEach((element, idx) => {

              let item: any = {};
              item.srno = idx + 1;
              item.itemDesc = element.itemDesc;
              item.qty = element.qtyOrdered;
              item.unit = element.unit || 0;
              item.itemName = element.itemName;
              item.itemNo = element.itemNo;
              item.glCode = element.msCode;
              item.itemDesc = element.itemDesc;
              item.id = element.id;
              item.orderId = element.orderId;
              item.jobdetailid=element.jobdetailid==undefined ? element.jobdetailId ==undefined ? 0 : element.jobdetailId : element.jobdetailid;
              //item.unitPrice = element.unitPrice;
              item.unitPrice = Utils.currencyFormat(element.unitPrice);


              let defaultitemname: any = [];
              let objPartname: any = {};
              objPartname.itemName = element.itemName;
              objPartname.label = element.itemName;
              objPartname.id = element.id;
              objPartname.glCode = element.msCode;
              objPartname.qty = element.qtyOrdered;
              objPartname.unit = element.itemName || 0;
              objPartname.unitPrice = element.unitPrice;
              objPartname.unit = element.unit;
              objPartname.tenantid = 0;

              defaultitemname.push(objPartname);

              item.defaultitemname = defaultitemname;

              misclistByItem.push(item);

            });
          }

          let jobOrderlistByItem = result.vendorOrderDetailsToUpdateList.filter((x) => !x.isAdditionItem);
          if (jobOrderlistByItem.length > 0) {
            jobOrderlistByItem.forEach((element, idx) => {
              element.unitPrice = Utils.currencyFormat(element.unitPrice);
            });

          }

          
          let additionalMaterialItems = result.vendorOrderItemDetails?.filter((x) => x.isAdditionItem) || [];


          if (additionalMaterialItems.length > 0) {
            misclistByItem = [];
            additionalMaterialItems.forEach((element, idx) => {

              let defaultitemname: any = [];
              let objPartname: any = {};

              objPartname.itemName = element.itemName;
              objPartname.label = element.itemName;
              objPartname.id = element.id;
              objPartname.glCode = element.msCode;
              objPartname.qty = element.qtyOrdered;
              objPartname.unitPrice = element.unitPrice;
              objPartname.unit = element.unit;
              objPartname.tenantid = 0;

              defaultitemname.push(objPartname);
              element.srno = idx + 1;
              element.defaultitemname = defaultitemname;
              element.unitPrice = Utils.currencyFormat(element.unitPrice);

              element.glCode = element.msCode;
              element.qty = element.qtyOrdered;

              let defaultjobno: any = [];

              let JODetails = Object.assign(
                [],
                JSON.parse(JSON.stringify(this.state.JODetails))
              );



              element.selectedIDs.forEach(selectedElement => {
                JODetails.forEach(jobDetail => {
                  if (jobDetail.jobid === selectedElement.jobid) {
                    jobDetail.isChecked = true;
                  }
                });
              });

              console.log("JO DETEAIL EXISTIG", JODetails);
              element.JODetails = JODetails;

              //   element.selectedIDs.forEach(element => {
              //     let objjobno: any = {};
              //     objjobno.jobNo = element.jobno;  
              //     objjobno.label = element.jobno; 
              //     objjobno.jobId = element.jobid; 
              //     objjobno.value = element.jobno;
              //     defaultjobno.push(objjobno);
              // });


              //   element.defaultjobno = defaultjobno;

            });
          }
          else
            additionalMaterialItems = this.state.additionalMaterialItems;

          console.log("updated additionmaterial", additionalMaterialItems);
          this.setState({ invoiceCreated: result.invoiceCreated, misclistByItem: misclistByItem, jobOrderlistByItem: jobOrderlistByItem, jobdetailsdata: jobdetails, additionalMaterialItems: additionalMaterialItems });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSaveSpinner: false });
        reject();
      });
  };

  onLoadData() {
    this.setState({ isjobcreate: false, isStateChanged: false });
    let pros = this.props;
    let requestlist = { ...this.state.requestlist };
    if (this.customerSearch.current !== null) {
      this.customerSearch.current.GetCustomerTranlist(requestlist);
    }
  }

  updateCustomerList = (result: any) => {
    this.setState({ customerList: [] }, () => {
      this.setState({ customerList: result });
    });
  };

  handleChange = (event: any, FieldType: string, index: any) => {
    let isStateChanged = true;
    let start = 0;
    let end = 0;



    let formData = { ...this.state.formData };
    let jobdetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobdetailsdata))
    );


    if (FieldType === "orderDate") {
      //   formData.OrderDate = event.target.value.trim().length > 0 ? event.target.value : "";
      let today = new Date(event);
      let dateNew =
        today.getMonth() +
        1 +
        "/" +
        today.getDate() +
        "/" +
        today.getFullYear().toString().substring(2, 4);
      let todaynew = new Date(dateNew);
      let dateNewt =
        todaynew.getMonth() +
        1 +
        "/" +
        todaynew.getDate() +
        "/" +
        todaynew.getFullYear().toString().substring(0, 4);
      formData.OrderDate = dateNewt;
      this.setState({ formData, isStateChanged });
    } else if (FieldType === "dueDate") {
      let today = new Date(event);
      let dateNew =
        today.getMonth() +
        1 +
        "/" +
        today.getDate() +
        "/" +
        today.getFullYear().toString().substring(2, 4);
      let todaynew = new Date(dateNew);
      let dateNewt =
        todaynew.getMonth() +
        1 +
        "/" +
        todaynew.getDate() +
        "/" +
        todaynew.getFullYear().toString().substring(0, 4);
      formData.duedate = dateNewt;
      this.setState({ formData, isStateChanged });
    } else if (FieldType === "check") {
      if (event.target.value.length > 0) {
        if (Number(event.target.value)) {
          formData.checkNo = Number(event.target.value);
        }
      } else {
        formData.checkNo = event.target.value;
      }
    } else if (FieldType === "repeat") {
      formData.isRepeat = event.target.checked ? "Yes" : "No";
      this.setState({ isRepeat: event.target.checked });
    } else if (FieldType === "ship_via") {
      formData.ship_via = event.target.value;
      this.setState({ ship_via: event.target.value });
    } else if (FieldType === "orderedqty") {

      event.preventDefault();
      let newVal = Utils.removeInvaildCharFromAmount(event.target.value);
      event.target.value = newVal;
      jobdetails[index].qtyOrdered = event.target.value;

      this.setState({ jobdetailsdata: jobdetails, isStateChanged }, () => {
        //$("#tblOrder")
        //  .find("input[id^='" + txtID + "']")
        //  .focus();


      });
    } else if (FieldType === "unitprice") {
      event.preventDefault();
      let newVal = Utils.removeInvaildCharFromAmount(event.target.value);
      event.target.value = newVal;
      jobdetails[index].unitPrice = event.target.value;

      this.setState({ jobdetailsdata: jobdetails, isStateChanged }, () => {
        let txtID = "txtUnitPrice" + index + "txt";
        $("#tbltemplate")
          .find("input[id^='" + txtID + "']")
          .focus();
      });


    }
    else if (FieldType === "jdescription") {
      event.preventDefault();
      jobdetails[index].jdescription = event.target.value;
      this.setState({ jobdetailsdata: jobdetails, isStateChanged }, () => {
        /*let txtID = "txtUnitPrice" + index + "txt";
        $("#tbltemplate")
         .find("input[id^='" + txtID + "']")
         .focus();*/
      });
    }
    else if (FieldType === "Message") {
      event.preventDefault();
      this.state.mailContent.Message = event.target.value;
    }
    else if (FieldType === "CC") {
      event.preventDefault();
      this.state.mailContent.CC = event.target.value;
    }
    else if (FieldType === "MailTo") {
      event.preventDefault();
      this.state.mailContent.MailTo = event.target.value;
    }






    this.setState({ formData, isStateChanged, isSpinner: false }, () => {
      if (FieldType === "invoiceno") {
        let txtID = "txtInvoice";
        let txtInvoice = $("input[id^='" + txtID + "']")[0] as any;
        txtInvoice.selectionStart = start;
        txtInvoice.selectionEnd = end;
      }
    });
  };


  handleEditClick = (rowIndex, currentDesc) => {
    console.log("desc---------------", currentDesc);
    let currentNote = "";
    
    if (this.state.vendorordertype === "Process") {
      currentNote = currentDesc[0]?.itemName ? currentDesc[0].itemName : "";
    } else {
      currentNote = currentDesc[0]?.itemName ? `${currentDesc[0].itemName}\n${currentDesc[0]?.itemDesc?currentDesc[0].itemDesc:""}` : "";
    }

    this.setState({
      editingIndex: rowIndex,
      currentNote: currentNote,
      showPopup: true,
    });
};

  handleNotesSave = () => {

    let additionalMaterialItems = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.additionalMaterialItems))
    );

    const { editingIndex, currentNote } = this.state;
    console.log("prev notes--------",additionalMaterialItems[editingIndex]);
    if( additionalMaterialItems[editingIndex].defaultitemname.length > 0 ){
    additionalMaterialItems[editingIndex].defaultitemname[0].itemName = currentNote;
    additionalMaterialItems[editingIndex].defaultitemname[0].label = currentNote.split('\n')[0];
    }else{
      let objPartname: any = {};
      objPartname.itemName = currentNote;
      objPartname.label = currentNote.split('\n')[0];

      additionalMaterialItems[editingIndex].defaultitemname.push(objPartname);
    }
    additionalMaterialItems[editingIndex].itemName = currentNote;

    this.setState({ showPopup: false, editingIndex: null, currentNote: '', additionalMaterialItems, isStateChanged: true });
  };

  amountBlurProcess = (e, rowIndex, type: any, row: any) => {

    let jobdetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobdetailsdata))
    );

    if (e.target.value.includes(".")) {
      const timeArray = e.target.value?.split(".");
      if (timeArray[1].length <= 0) {
        if (type === "unitPrice") {
          jobdetails[rowIndex].unitPrice = Utils.currencyFormat(
            timeArray[0]
          );

        }
      } else {
        if (type === "unitPrice") {

          jobdetails[rowIndex].unitPrice = Utils.currencyFormat(
            e.target.value
          );

        }


      }
      //this.setState({ jobdetails });
      this.setState({ jobdetailsdata: jobdetails }, () => { });
    } else {
      if (type === "unitPrice") {


        jobdetails[rowIndex].unitPrice = e.target.value === ""
          ? ""
          : !isNaN(e.target.value)
            ? Utils.currencyFormat(e.target.value)
            : e.target.value;

      }

      this.setState({ jobdetailsdata: jobdetails }, () => { });
    }
  };

  onAmountFocusProcess = (e, index, txtID, type: any) => {


    let jobdetails = Object.assign([], JSON.parse(JSON.stringify(this.state.jobdetailsdata)));
    //let newval = Utils.removecurrencyFormat(e.target.value);
    let start = e.target?.selectionStart;
    let end = e.target?.selectionEnd;
    if (type === "unitPrice") {
      let newVal = Utils.removeInvaildCharFromAmount(e.target.value);
      e.target.value = newVal;
      jobdetails[index].unitPrice = e.target.value;

      this.setState({ jobdetailsdata: jobdetails }, () => {
        $("#tbltemplate")
          .find("input[id^='" + txtID + "']")
          .focus();
        let txtdesc = $("#tbltemplate").find(
          "input[id^='" + txtID + "']"
        )[0] as any;
        if (txtdesc !== null && txtdesc !== undefined) {
          txtdesc.selectionStart = start;
          txtdesc.selectionEnd = end;
        }
      });
    }
  };

  handleDatePicker = (event: any, refName: any) => {
    refName.current.setOpen(true);
  };

  handleVendorChange = (e: any) => {

    let isStateChanged = true;
    let formData = { ...this.state.formData };
    if (e.length > 0) {
      this.setState({
        CustomerName: e[0].company_name,
        address: e[0].address,
        customercode: e[0].vendorcode,
        isStateChanged: isStateChanged,
      });
      this.state.formData.CustomerID = e[0].vendor_id;
      this.state.formData.CustomerName = e[0].company_name;
      this.state.formData.ship_via = e[0].ship_via;

      this.state.formData.Address =
        e[0].firstname +
        "\n" +
        e[0].address +
        "\n" +
        e[0].apartment +
        "\n" +
        e[0].city +
        ", " +
        e[0].state +
        ", " +
        e[0].zipcode +
        "\n" +
        e[0].country;
      this.state.formData.customercode = e[0].vendorcode;

      let options: any = [];
      e[0].vendorContact.forEach(element => {

        let item: any = {};
        item.id = element.id;
        item.name = element.firstname;
        options.push(item);

      });

      let objData: any = {};
      let Defaultselected: any = [];
      let defaultname: any = e[0].vendorContact.filter(
        (x) => x.isDefault == true
      );
      if (defaultname.length > 0) {

        objData.id = defaultname[0].firstname;
        objData.name = defaultname[0].firstname;
        Defaultselected.push(objData);
        formData.firstname = defaultname[0].firstname;
        this.state.formData.firstname = defaultname[0].firstname;
      };


      // this.state.options = options;
      this.setState({
        options: options, Defaultselected: Defaultselected,
      });
      //this.GetProductlist(requestlist);

      //this.GetorderEntryColoumn();

      let txtID = "txtOrderDate";
      setTimeout(() => {
        this.trDate.current.setOpen(true);
        $("input[id^='" + txtID + "']").focus();
      }, 50);
    } else {
    }
  };



  handleDiscard(postID) {
    this.setState({ isStateChanged: false }, () => {
      this.closeSlideOut();
    });
  }

  handleEdit() {
    this.setState({ isStateChanged: true, editMode: true, showOptionSelect: true });
  }

  onTAB = (control, rowIndex1, event) => {
    let controlID = control.split("-")[0];
    let rowIndex = Number(rowIndex1);

    if (event.key.toLowerCase() === "enter") {
      if (controlID === "txtOrderDate") {
        let txtID = "txtNotes";
        setTimeout(() => {
          $("input[id^='" + txtID + "']").focus();
        }, 50);
      } else if (controlID === "txtDueDate") {
        let txtID = "txtNotes";
        setTimeout(() => {
          $("input[id^='" + txtID + "']").focus();
        }, 50);
      }
    }

    if (event.key.toLowerCase() === "tab") {
      let ChecktxtID = "partname" + rowIndex + "ddl";

      if (controlID === ChecktxtID) {
        let txtID = "txtpartno" + rowIndex + "txt";

        $("#tblOrder")
          .find("input[id^='" + txtID + "']")
          .focus();
      }

      if (controlID === "txtduedate") {
        let txtID = "txtdescription" + rowIndex + "txt";

        $("#tblOrder")
          .find("input[id^='" + txtID + "']")
          .focus();
      }

      if (controlID === "txtOrderDate") {
        let txtID = "txtNotes";
        setTimeout(() => {
          $("input[id^='" + txtID + "']").focus();
        }, 50);
      }
    }

    if (event.key.toLowerCase() === "backspace") {
      let controlID = control.split("-")[0];
      let rowIndex = Number(rowIndex1);
      let orderData = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.orderData))
      );
      let defaultPartname: any = [];
      let productList = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.productList))
      );

      orderData[rowIndex].partname = event.target.value;
      orderData[rowIndex].productid = 0;
      orderData[rowIndex].partNo = "";
      orderData[rowIndex].unitPrice = 0;
      orderData[rowIndex].unit = "";
      orderData[rowIndex].jobDesc = "";

      let objPartname: any = {};
      objPartname.partname = event.target.value;
      objPartname.label = event.target.value;
      objPartname.id = 0;
      objPartname.partno = "";

      objPartname.tenantid = 0;
      objPartname.unit = "";
      objPartname.unitPrice = 0;
      objPartname.noofday = null;
      objPartname.jobDesc = "";
      defaultPartname.push(objPartname);
      productList.push(objPartname);

      //element.defaultPartname = defaultPartname;
      orderData[rowIndex].defaultPartname = defaultPartname;

      this.setState({ orderData, defaultPartname, productList }, () => {
        let txtID =
          "rbt-input-main form-control rbt-input partname" + rowIndex + "ddl";
        $("#tblOrder")
          .find("input[class^='" + txtID + "']")
          .focus();
      });

      let txtID =
        "rbt-input-main form-control rbt-input partname" + rowIndex + "ddl";

      let tvalue =
        event.target.value.trim().length > 0 ? event.target.value : "";
      if (tvalue === "") {
        setTimeout(() => {
          $("input[class^='" + txtID + "']").focus();
        }, 50);
      }
    }
  };

  HidecloseSlideOut() {
    this.props.closeSlideOut();
    this.setState({ isSaveSpinner: false });
  }


  Hidespinner() {
    this.setState({ isSpinner: false });
  }

  saveOrderfinal = (entryType, isSubmit, isprint) => {
    console.log("this.state.formData", this.state.formData);
    let isFormValid = true;
    let request: any = {};
    let orderlistitem: any = [];
    let jobOrderList = this.state.jobOrderlistByItem;
    let additionalMaterialItems = this.state.additionalMaterialItems;
    let misclistByItem = this.state.misclistByItem;
    let Vendor3PartyOrderItems: any = [];

    jobOrderList.forEach(element => {
      console.log("additionalMaterialItems", additionalMaterialItems);
      let item: any = {};
      
      if (element.selected) {
        item.orderID = element.orderId;
        item.itemNo = element.itemId;
        item.itemname = element.itemName;
        item.glcode = element.glCode;
        item.dueDate = element.dueDate === "" || element.dueDate === undefined || element.dueDate === null ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime)) : element.dueDate;
        item.jobDesc = element.jobDesc;
        item.unit = element.unit;
        item.unitPrice = Utils.removeInvaildCharFromAmount(element.unitPrice);

        if (item.unitPrice == "" || item.unitPrice == null) {

          item.unitPrice = 0;
        }

        item.qtyOrdered = element.qty;
        item.discount = element.discount;
        item.total = element.total;



        item.tenantid = element.tenantid;
        item.JobId = element.jobId;

        if (element.productid == 0 || element.productid == null || element.productid == undefined) {
          item.productid = 0;
        }
        else {
          if (element.productid[0] == undefined) {
            item.productid = 0;

          }
          else {
            item.productid = element.productid[0].id === undefined ? element.productid : element.productid[0].id;
          }
        }
        orderlistitem.push(item);
      }
    });




    //OrderItems items
    additionalMaterialItems.length > 0 && this.state.vendorordertype !== 'Miscellaneouss' && additionalMaterialItems.forEach(element => {
      

      let jobIds;
      // if (element.jobId == undefined) {
      //   jobIds = [];

      // }
      // else {
        
      jobIds = element.selectedIDs;
      // let jobIds = element.jobId.split(',');
      // }
      console.log("jobids........", element.jobId);
      //element.jobId.split(',');

      if (jobIds != undefined) {
        
        if (jobIds.length == 0) {



          let item: any = {};

          if (element.itemName !== '' && element.processname !== '') {
            item.orderID = element.orderId == undefined ? element.orderid : element.orderId;
            item.itemNo = element.itemNo;

            item.itemname = element.processname || element.itemName;

            item.glcode = element.glCode == undefined ? "" : element.glCode;
            item.dueDate = element.dueDate === "" || element.dueDate === undefined || element.dueDate === null
              ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
              : element.dueDate;
            item.jobDesc = element.jdescription || element.itemDesc;
            item.unit = element.unit;
            item.unitPrice = Utils.removeInvaildCharFromAmount(element.unitPrice);
            if (item.unitPrice == "" || item.unitPrice == null) {
              item.unitPrice = 0;
            }

            item.qtyOrdered = element.qty;
            item.total = element.total;
            item.isAdditionItem = true;
            item.tenantid = element.tenantid;
            item.JobId = 0;
            item.jobdetailId = element.jobdetailId == undefined ? element.jobdetailid==undefined ? 0 : element.jobdetailid : element.jobdetailId;
            // Handle productid
            if (element.productid == 0 || element.productid == null || element.productid == undefined) {
              item.productid = 0;
            } else {
              if (element.productid[0] == undefined) {
                item.productid = 0;
              } else {
                item.productid = element.productid[0].id === undefined ? element.productid : element.productid[0].id;
              }
            }

            // Push the item with the specific jobId into the list
            orderlistitem.push(item);
          }


        }
        else {
          jobIds.forEach(jobId => {
            let item: any = {};
            
            if (element.itemName !== '' && element.processname !== '') {
              
              item.orderID = element.orderId == undefined ? element.orderid : element.orderId;
              item.itemNo = element.itemNo;
              //  item.itemname = element.itemName;

              item.itemname = element.processname || element.itemName;


              item.glcode = element.glCode == undefined ? "" : element.glCode;
              item.dueDate = element.dueDate === "" || element.dueDate === undefined || element.dueDate === null
                ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
                : element.dueDate;
              item.jobDesc = element.jdescription || element.itemDesc;
              item.unit = element.unit;
              item.unitPrice = Utils.removeInvaildCharFromAmount(element.unitPrice);
              if (item.unitPrice == "" || item.unitPrice == null) {
                item.unitPrice = 0;
              }

              item.qtyOrdered = element.qty;
              item.total = element.total;
              item.isAdditionItem = true;
              item.tenantid = element.tenantid;
              item.JobId = element.selectedIDs == undefined ? parseInt(jobId) : parseInt(jobId.jobid);
              item.jobdetailId =element.jobdetailId == undefined ? element.jobdetailid==undefined ? 0 : element.jobdetailid : element.jobdetailId;
              // Handle productid
              if (element.productid == 0 || element.productid == null || element.productid == undefined) {
                item.productid = 0;
              } else {
                if (element.productid[0] == undefined) {
                  item.productid = 0;
                } else {
                  item.productid = element.productid[0].id === undefined ? element.productid : element.productid[0].id;
                }
              }

              // Push the item with the specific jobId into the list
              orderlistitem.push(item);
            }
          });
        }
      }
      else
      {

        let item: any = {};

        if (element.itemName !== '' && element.processname !== '') {
          item.orderID = element.orderId == undefined ? element.orderid ==undefined ? 0 : element.orderid  : element.orderId;
          item.itemNo = element.itemNo;

          item.itemname = element.processname || element.itemName;

          item.glcode = element.glCode == undefined ? "" : element.glCode;
          item.dueDate = element.dueDate === "" || element.dueDate === undefined || element.dueDate === null
            ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
            : element.dueDate;
          item.jobDesc = element.jdescription || element.itemDesc;
          item.unit = element.unit;
          item.unitPrice = Utils.removeInvaildCharFromAmount(element.unitPrice);
          if (item.unitPrice == "" || item.unitPrice == null) {
            item.unitPrice = 0;
          }

          item.qtyOrdered = element.qty;
          item.total = 0;
          item.isAdditionItem = true;
          item.tenantid = 0;
          item.JobId = 0;
          item.jobdetailId = element.jobdetailId == undefined ? element.jobdetailid==undefined ? 0 : element.jobdetailid : element.jobdetailId;
          // Handle productid
          if (element.productid == 0 || element.productid == null || element.productid == undefined) {
            item.productid = 0;
          } else {
            if (element.productid[0] == undefined) {
              item.productid = 0;
            } else {
              item.productid = element.productid[0].id === undefined ? element.productid : element.productid[0].id;
            }
          }

          // Push the item with the specific jobId into the list
          orderlistitem.push(item);
        }        
      }



    });

    this.state.formData.OrderItems = orderlistitem;
    ///////

    //VendorOrderItemDetails
    let orderItemDetails: any = [];
    if (this.state.vendorordertype === 'Miscellaneouss') {
      console.log("pppp", this.state.misclistByItem);
      let itemList = this.state.misclistByItem;
      itemList.forEach(element => {
        if (element.itemName != '') {
          let item: any = {};
          let orderjob: any = [];

          item.id = element.id;
          item.orderId = orderlistitem.length > 0 ? orderlistitem[0].orderID : 0;
          item.itemNo = element.itemNo;
          item.itemname = element.itemName;
          item.ItemDesc = element.itemDesc == "" ? element.itemName : element.itemDesc;
          item.MSCode = element.glCode;
          item.Unit = element.unit;
          item.ProductId = element.itemNo;
          item.unitPrice = Utils.removeInvaildCharFromAmount(element.unitPrice);
          item.qtyOrdered = element.qty;
          item.TenantId = orderlistitem.length > 0 ? orderlistitem[0].tenantid : 0;
          let jobIds = element.selectedIDs == undefined ? element.jobId.split(',') : element.selectedIDs;

          jobIds.forEach(jobId => {
            let itemjob: any = {};
            itemjob.jobid = element.selectedIDs == undefined ? parseInt(jobId) : parseInt(jobId.jobid);
            orderjob.push(itemjob);
          });

          item.selectedIds = orderjob;
          orderItemDetails.push(item);
        }
      });

      // misclistByItem.forEach(element => {
      //   let orderjob: any = [];
      //   let item: any = {};
      //   if (element.itemName !== '') {
      //     item.id = element.id;
      //     item.orderID = orderlistitem.length > 0 ? orderlistitem[0].orderID : 0;
      //     item.itemNo = element.itemNo;
      //     item.itemname = element.itemName;
      //     item.ItemDesc = element.itemDesc == "" ? element.itemName : element.itemDesc;
      //     item.MSCode = element.glCode;
      //     item.Unit = element.unit;
      //     item.unitPrice = Utils.removeInvaildCharFromAmount(element.unitPrice);
      //     item.qtyOrdered = element.qty;
      //     item.TenantId = orderlistitem.length > 0 ? orderlistitem[0].tenantid : 0;
      //     item.isAdditionItem = true;


      //     let jobIds = element.selectedIDs ==undefined ?  element.jobId.split(',') :element.selectedIDs ;

      //     jobIds.forEach(jobId => {
      //       let itemjob: any = {};
      //       itemjob.jobid=element.selectedIDs ==undefined ?    parseInt(jobId) : parseInt(jobId.jobid);  
      //       orderjob.push(itemjob);
      //     });

      //     item.selectedIds=orderjob;
      //     console.log("ooooooooooo",orderjob);
      //     orderItemDetails.push(item);
      //   }

      // });

    }
    else {
      let itemList = jobOrderList.filter((x) => x.rowType == 1 && jobOrderList.filter((y) => y.selected == true && y.itemId == x.itemId).length > 0);
      itemList.forEach(element => {

        let item: any = {};

        if (element.itemName != '') {
          item.id = element.id;
          item.orderId = orderlistitem[0].orderID;
          item.itemNo = element.itemId;
          item.itemname = element.itemName;
          item.ItemDesc = element.itemDesc == "" ? element.itemName : element.itemDesc;
          item.MSCode = element.glCode;
          item.Unit = element.unit;
          item.unitPrice = Utils.removeInvaildCharFromAmount(element.unitPrice);
          item.qtyOrdered = element.qty;
          item.TenantId = orderlistitem[0].tenantid;
          item.jobdetailId = element.jobdetailId == undefined ? element.jobdetailid==undefined ? 0 : element.jobdetailid : element.jobdetailId;

          orderItemDetails.push(item);
        }

      });

      additionalMaterialItems.forEach(element => {
        
        let orderjob: any = [];
        let item: any = {};
        if (element.itemName !== '' && element.processname !== '') {
          item.id = element.id;
          // item.orderID = orderlistitem[0].orderID;
          item.orderId = orderlistitem.length > 0 ? orderlistitem[0].orderID : 0;

          item.itemNo = element.itemNo;
          // item.itemname =this.state.vendorordertype === 'Process' ?  element.processname : element.itemName ;
          // item.ItemDesc = element.itemDesc == "" ? element.itemName : element.itemDesc;
          item.itemname = element.processname || element.itemName;
          item.ItemDesc = element.jdescription || element.itemDesc;
          item.MSCode = element.glCode;
          item.Unit = element.unit;
          item.unitPrice = Utils.removeInvaildCharFromAmount(element.unitPrice);
          item.qtyOrdered = element.qty;
          // item.TenantId = orderlistitem[0].tenantid;
          item.TenantId = orderlistitem.length > 0 ? orderlistitem[0].tenantid : 0;
          item.isAdditionItem = true;
          item.selectedIDs = element.selectedIDs;
          item.jobdetailId = element.jobdetailId == undefined ? element.jobdetailid==undefined ? 0 : element.jobdetailid : element.jobdetailId;

          //let jobIds = element.jobId.split(',');
          // let jobIds = element.selectedIDs == undefined ? element.jobId.split(',') : element.selectedIDs;
          if (item.selectedIDs && item.selectedIDs.length == 0) {

            let jobIds;
            if (element.jobId == undefined || element.selectedIDs == undefined) {
              jobIds = [];

            }
            else {

              jobIds = element.selectedIDs == undefined ? element.jobId.split(',') : element.selectedIDs;
              // let jobIds = element.jobId.split(',');
            }
            jobIds.forEach(jobId => {

              let itemjob: any = {};
              itemjob.jobid = element.selectedIDs == undefined ? parseInt(jobId) : parseInt(jobId.jobid);
              orderjob.push(itemjob);
            });

            item.selectedIds = orderjob;
          }
          console.log("ooooooooooo", orderjob);
          orderItemDetails.push(item);
        }

      });
    }

    this.state.formData.VendorOrderItemDetails = orderItemDetails;
    //////

    if (this.state.vendorordertype === 'Process') {

      let saveItems: any = [];
      let jobdetailsList = this.state.jobdetailsdata;
      let isvalid: any = 0;


      if (isvalid == 0) {
        additionalMaterialItems.forEach(element => {
          let item: any = {};
     
            let jobIds;
    
debugger;
 if (jobIds != undefined) {
  debugger;
        if (jobIds.length == 0) {
      jobIds = element.selectedIDs;
          jobIds.forEach(jobId => {
          
          item.orderid = element.orderid || element.orderId;
          item.jobid =element.selectedIDs == undefined ? parseInt(jobId) : parseInt(jobId.jobid);
          item.processid = element.processid || element.defaultitemname[0].id;
          item.processname = element.processname || element.defaultitemname[0].itemName;
          item.type = element.type;
          item.MSCode = element.glCode || element.defaultitemname[0].glCode;
          item.jdescription = element.jdescription || element.itemDesc;
          item.tenantid = element.tenantid || element.tenantId;
          item.qtyOrdered = element.qty;
          item.unitPrice = Utils.removeInvaildCharFromAmount(element.unitPrice);
          item.jobdetailId = element.jobdetailId == undefined ? element.jobdetailid==undefined ? 0 : element.jobdetailid : element.jobdetailId;
          saveItems.push(item);
          
          });
        }
        else
        {
          debugger;
          item.orderid = this.state.vendororderid;
          item.jobid =0;
          item.processid = element.processid || element.defaultitemname[0].id;
          item.processname = element.processname || element.defaultitemname[0].itemName;
          item.type = element.type;
          item.MSCode = element.glCode || element.defaultitemname[0].glCode;
          item.jdescription = element.jdescription || element.itemDesc;
          item.tenantid = element.tenantid || element.tenantId;
          item.qtyOrdered = element.qty;
          item.unitPrice = Utils.removeInvaildCharFromAmount(element.unitPrice);
          item.jobdetailId = element.jobdetailId == undefined ? element.jobdetailid==undefined ? 0 : element.jobdetailid : element.jobdetailId;
          saveItems.push(item);
        }
      
      }
        else
        {

            
          item.orderid = this.state.vendororderid;
          item.jobid =0;
          item.processid = element.processid || element.defaultitemname[0].id;
          item.processname = element.processname || element.defaultitemname[0].itemName;
          item.type = element.type;
          item.MSCode = element.glCode || element.defaultitemname[0].glCode;
          item.jdescription = element.jdescription || element.itemDesc;
          item.tenantid = element.tenantid || element.tenantId;
          item.qtyOrdered = element.qty;
          item.unitPrice = Utils.removeInvaildCharFromAmount(element.unitPrice);
          item.jobdetailId = element.jobdetailId == undefined ? element.jobdetailid==undefined ? 0 : element.jobdetailid : element.jobdetailId;
          saveItems.push(item);
        }
          // }
        });



        console.log("saveeee", saveItems);

        this.state.formData.Vendor3PartyOrderItems = saveItems;
      }
    }
    if (this.state.vendorordertype === 'Process') {
      // if (this.state.formData.Vendor3PartyOrderItems.length == 0) {
      //   //alert('Please select at least one item to save');
      //   toast.error("Please select at least one item to save", {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     containerId: "OrderSlideout",
      //   });

      //   return;
      // }



      if (this.state.formData.Vendor3PartyOrderItems.filter((x) => x.qtyOrdered < 0 || x.qtyOrdered == 0 || x.qtyOrdered == "" || x.qtyOrdered == undefined).length != 0) {

        toast.error("Please check Qty.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });
        return;
      }

      // if(this.state.formData.Vendor3PartyOrderItems.filter((x) => Number(Utils.removeInvaildCharFromAmount(x.unitPrice)) <0 || Number(Utils.removeInvaildCharFromAmount(x.unitPrice)) ==0 || x.unitPrice ==""  || x.unitPrice ==undefined).length != 0)
      // {

      //   toast.error("Please check Unit price.", {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     containerId: "OrderSlideout",
      //   });
      //   return;
      // }
    }
    else if (this.state.vendorordertype === 'Miscellaneouss') {
      if (this.state.formData.VendorOrderItemDetails.length == 0) {
        //alert('Please select at least one item to save');
        toast.error("Please add at least one item to save", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });
        return;
      }

      if (this.state.formData.VendorOrderItemDetails.filter((x) => x.qtyOrdered == 0 || x.qtyOrdered == '' || x.qtyOrdered == undefined).length > 0) {
        //alert('Please select at least one item to save');
        toast.error("Please check Qty.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });
        return;
      }

      // if(this.state.formData.VendorOrderItemDetails.filter((x)=>Number(Utils.removeInvaildCharFromAmount(x.unitPrice)) == 0 || Utils.removeInvaildCharFromAmount(x.unitPrice)=='').length > 0)
      // {
      //          //alert('Please select at least one item to save');
      //          toast.error("Please check Unit Price.", {
      //           position: toast.POSITION.BOTTOM_RIGHT,
      //           containerId: "OrderSlideout",
      //         });
      //          return;
      // }
    }
    else {

      //       let itemList  = jobOrderList.filter((x)=>x.rowType == 2 && ( x.qty<0 || x.qty=="") && jobOrderList.filter((y)=> y.selected == true && y.itemId == x.itemId).length > 0);


      // if(itemList!=0)
      // {
      //      //alert('Please select at least one item to save');
      //      toast.error("Please check qty.", {
      //       position: toast.POSITION.BOTTOM_RIGHT,
      //       containerId: "OrderSlideout",
      //     });
      //      return;
      // }

      // if (this.state.formData.OrderItems.length == 0) {
      //   //alert('Please select at least one item to save');
      //   toast.error("Please select at least one item to save", {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     containerId: "OrderSlideout",
      //   });
      //   return;
      // }






      if (this.state.formData.VendorOrderItemDetails.filter((x) => x.qtyOrdered == 0 || x.qtyOrdered == '' || x.qtyOrdered == undefined).length > 0) {
        //alert('Please select at least one item to save');
        toast.error("Please check Qty.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });
        return;
      }

      // if(this.state.formData.VendorOrderItemDetails.filter((x)=>Number(Utils.removeInvaildCharFromAmount(x.unitPrice)) == 0 || Utils.removeInvaildCharFromAmount(x.unitPrice)=='').length > 0)
      // {
      //          //alert('Please select at least one item to save');
      //          toast.error("Please check Unit Price.", {
      //           position: toast.POSITION.BOTTOM_RIGHT,
      //           containerId: "OrderSlideout",
      //         });
      //          return;
      // }

      if (additionalMaterialItems.filter((x) => x.qty == 0 && x.itemName !== '').length > 0) {
        //alert('Please select at least one item to save');
        toast.error("Please check Qty on Additional Section.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });
        return;
      }

      // if(additionalMaterialItems.filter((x)=>Number(Utils.removeInvaildCharFromAmount(x.unitPrice)) == 0  && x.itemName !== '').length > 0)
      // {
      //          //alert('Please select at least one item to save');
      //          toast.error("Please check Unit Price on Additional Section.", {
      //           position: toast.POSITION.BOTTOM_RIGHT,
      //           containerId: "OrderSlideout",
      //         });
      //          return;
      // }

      // if(additionalMaterialItems.filter((x)=>(x.jobNo == undefined  || x.jobNo == '') && x.itemName !== '').length > 0)
      //   {
      //            //alert('Please select at least one item to save');
      //            toast.error("Please check Job on additional section.", {
      //             position: toast.POSITION.BOTTOM_RIGHT,
      //             containerId: "OrderSlideout",
      //           });
      //            return;
      //   }


    }

    if (this.state.formData.CustomerID === 0) {
      toast.error("Please select Vendor.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });

      return false;
    }



    if (new Date(this.state.formData.duedate) < new Date(this.state.formData.OrderDate)) {
      toast.error("Due Date cannot be less then order date.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "OrderSlideout",
      });

      return false;
    }


    this.setState({ ispopupshow: false, isSpinner: true });
    this.state.formData.VendorOrderType = this.state.vendorordertype;
    this.state.formData.POInitiated = this.state.formData.POInitiated == true ? this.state.formData.POInitiated : isSubmit;
    request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
    // request.Vendor3PartyOrderItems = Vendor3PartyOrderItems;
    request.contactName = this.state.formData.firstname;




    isFormValid = true;


    if (isFormValid) {
      console.log("req save", request);
      this.setState({ ispopupshow: false });
      VendorService.Saveorder(request)
        .then(async (result: any | null) => {
          if (result !== null) {
            if (this.state.vendororderid === 0 || this.state.vendororderid == undefined) {
              this.state.formData.OrderID = result.orderID;
              this.state.formData.CustomerPoNumber = result.vendorPoNumber;
              this.setState({ vendororderid: result.orderID, isApproved: true, });
              let message = result.vendorPoNumber + " order has been created.";
              toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "OrderSlideout",
              });
            }
            else {

              let message = "The Order has been updated.";
              toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "OrderSlideout",
              });

            }

            if (isSubmit == true) {

              this.closeSlideOut();
            }

            this.setState({ showAfterSaveModelPopup: false, editMode: false, isSpinner: false, isStateChanged: false, });

            if (isprint == 1) {
              this.printOrder();
            }
            if (isprint == 2) {

              this.mailOrder();
            }


          }
          resolve();
        })
        .catch((error) => {
          this.setState({ isdefaultColDisabled: false, isSpinner: false });
          reject();
        });
    }
  };


  saveOrder = (entryType, isSubmit) => {
    this.saveOrderfinal("order", isSubmit, 0);
  };

  deleteVendorOrder = () => {
    this.setState({ isSpinner: true });
    let request = { ...this.state.requestlist };
    request.vendororderid = this.state.vendororderid;

    VendorService.DeleteVendorOrder(request)
      .then(async (result: any | null) => {
        if (result !== null) {

          this.closeSlideOut();

          toast.success("Vendor Order has been deleted.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });



          this.setState({ isSpinner: false });

        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isdefaultColDisabled: false, isSpinner: false });
        reject();
      });
  };


  downloadVendorOrderFile = (file: any) => {
    let request: any = {};
    request.orderid = this.state.vendororderid;
    request.tenantid = 0;
    request.vendorordertype = this.state.vendorordertype;
    request.jobid = this.props.jobid;

    this.setState({ isSpinner: true });

    VendorService.DownloadVendorOrderFile(request)
      .then(async (result: any | null) => {
        //this.HidecloseSlideOut();
        this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
      });
  };

  SendMailToVendor = () => {
    let request: any = {};
    request.orderid = this.state.vendororderid;
    request.tenantid = 0;
    request.vendorordertype = this.state.vendorordertype;
    request.jobid = this.props.jobid;

    //let selectedVendor = this.state.customerList.filter((x) => x.vendor_id === this.state.formData.CustomerID); 
    if (this.state.mailContent.MailTo == '')
      return;

    let item: any = {};
    item.mailTo = this.state.mailContent.MailTo; //selectedVendor[0].email;
    item.mailCC = this.state.mailContent.CC;
    item.subject = 'Vendor Order';
    item.mailBody = this.state.mailContent.Message; //3'Please find the attachment for order detail.';
    item.isBodyHtml = true;
    item.sendFrom = '';
    item.mailFrom = '';
    this.setState({ isSpinner: true });
    VendorService.SendMailToVendor(request, item)
      .then(async (result: any | null) => {
        //this.HidecloseSlideOut();
        toast.success("Email sent to vendor.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "OrderSlideout",
        });

        this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
      });
  };


  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      //this.setState({ showSlideout: true ,orderid:row.orderID
      // });
    },
  };


  handleRowSelectionCheckAllJob = () => {
    console.log("handleRowSelectionCheckAllJob");
    if (this != undefined) {

      let data = this.state.jobdetailsdata.filter((x) => x.selected === true || this.state.editMode || this.state.vendororderid == 0);

      if (data.length > 0) {
        data.forEach((element: any, index: any) => {
          //  this.handleRowSelectionn(e,0,prevorderid);
          this.handleRowSelectionCheckJob("", index, element, "all");
        });
      }

      console.log("data", data);
      //  this.setState(prevState => ({
      //   additionalMaterialItems: [...prevState.additionalMaterialItems, data]    }));
    }
  };


  // handleRowSelectionCheck = (e, rowData, row, selected) => {
  //   let updatedItems = [...this.state.additionalMaterialItems];
  //  console.log("before",updatedItems);
  //   // Remove the last item in the array (delete the last row)
  //   updatedItems.pop();  
  //   console.log("aftre",updatedItems);


  //   let JODetails = Object.assign(
  //     [],
  //     JSON.parse(JSON.stringify(this.state.JODetails))
  //   );


  //   JODetails.forEach(jobDetail => {
  //     if (jobDetail.jobid === row.jobId) {
  //       jobDetail.isChecked = true;
  //     }
  //   });

  //   console.log(":ppppp", this.state.jobOrderlistByItem);

  //   console.log("row select", row, rowData);
  //   console.log("selected", selected);
  //   console.log("material select", this.state.additionalMaterialItems);


  //   const newMaterialItem = {
  //     ...row,
  //     srno: this.state.additionalMaterialItems.length + 1,
  //     defaultitemname: [{
  //       itemName: row.itemName,
  //       label: row.itemName,
  //       unitPrice: row.unitPrice,
  //       qty: row.qty,
  //       id: row.itemId,
  //     }],
  //     defaultjobno: [{
  //       jobNo: row.jobNo,
  //       label: row.jobNo,
  //       jobId: row.jobId
  //     }],
  //     defaultcustomerPoNumber: [{
  //       customerPoNumber: row.customerPoNumber,
  //       label: row.customerPoNumber,
  //       id: row.id
  //     }],
  //     JODetails: JODetails,
  //     selectedIDs: [{
  //       id: 0,
  //       jobid: row.jobId,
  //       jobno: row.jobNo
  //     }]
  //   };


  //   // this.setState(prevState => ({
  //   //   additionalMaterialItems: [...prevState.additionalMaterialItems, newMaterialItem]
  //   // }));





  //   // this.setState(prevState => {
  //   //   const updatedItems = [...prevState.additionalMaterialItems];
  //   //   if (updatedItems.length > 0 && updatedItems[updatedItems.length - 1].glCode === "" && updatedItems[updatedItems.length - 1].defaultitemname.length === 0 && updatedItems[updatedItems.length - 1].defaultjobno.length === 0 && updatedItems[updatedItems.length - 1].qty === 0 && updatedItems[updatedItems.length - 1].unit === 0 && updatedItems[updatedItems.length - 1].unitPrice === Utils.currencyFormat(0)) {
  //   //     console.log("old material for first",updatedItems[updatedItems.length - 1].JODetails);

  //   //     console.log("new material for first",newMaterialItem);
  //   //     updatedItems[updatedItems.length - 1] = {
  //   //       ...newMaterialItem,
  //   //       srno: updatedItems.length
  //   //     };
  //   //   } else {
  //   //     updatedItems.push(newMaterialItem);
  //   //   }

  //   //   return {
  //   //     additionalMaterialItems: updatedItems
  //   //   };
  //   // });

  //   updatedItems.push(newMaterialItem);
  //   this.setState({ additionalMaterialItems: updatedItems });


  // };


  handleRowSelectionCheck = (e, rowData, row, selected) => {
    let updatedItems = [...this.state.additionalMaterialItems];

    if (updatedItems[updatedItems.length - 1].glCode === "" && updatedItems[updatedItems.length - 1].defaultitemname.length === 0 && updatedItems[updatedItems.length - 1].defaultjobno.length === 0 && updatedItems[updatedItems.length - 1].qty === 0 && updatedItems[updatedItems.length - 1].unit === 0 && updatedItems[updatedItems.length - 1].unitPrice === Utils.currencyFormat(0)) {
      updatedItems.pop();
    }

    this.setState({ additionalMaterialItems: updatedItems }, () => {
      this.updateRowSelectionCheck(e, rowData, row, selected);
    });
  };


  updateRowSelectionCheck = (e, rowData, row, selected) => {
    let updatedItems = [...this.state.additionalMaterialItems];
    let JODetails = this.state.JODetails.map(jobDetail => ({
      ...jobDetail,
      isChecked: jobDetail.jobid === row.jobId ? true : jobDetail.isChecked,
    }));

    const newMaterialItem = {
      ...row,
      srno: this.state.additionalMaterialItems.length + 1,
      defaultitemname: [{
        itemName: row.itemName,
        label: row.itemName,
        unitPrice: row.unitPrice,
        qty: row.qty,
        id: row.itemId,
      }],
      defaultjobno: [{
        jobNo: row.jobNo,
        label: row.jobNo,
        jobId: row.jobId,
      }],
      defaultcustomerPoNumber: [{
        customerPoNumber: row.customerPoNumber,
        label: row.customerPoNumber,
        id: row.id,
      }],
      JODetails: JODetails,
      jobdetailId: row.jobdetailId==undefined ? row.jobdetailid : row.jobdetailId,
      selectedIDs: [{
        id: 0,
        jobid: row.jobId,
        jobno: row.jobNo,
      }]
    };

    updatedItems.push(newMaterialItem);
    this.setState({ additionalMaterialItems: [...updatedItems] }, () => {
      console.log("State updated with new material item:", this.state.additionalMaterialItems);
    });
  };


  handleRowSelectionCheckJob = (e, rowData, row, selected) => {
    let updatedItems = [...this.state.additionalMaterialItems];
   console.log("updated",updatedItems,row);
   const duplicateJobId = updatedItems.some(item => item.jobid === row.jobid);
   if (duplicateJobId) {
     toast.error("Duplicate record not allowed", {
       position: toast.POSITION.BOTTOM_RIGHT,
       containerId: "OrderSlideout",
     });
     return; 
   }
    if(updatedItems[updatedItems.length - 1].glCode === "" && updatedItems[updatedItems.length - 1].defaultitemname.length === 0 && updatedItems[updatedItems.length - 1].defaultjobno.length === 0 && updatedItems[updatedItems.length - 1].qty === 0 && updatedItems[updatedItems.length - 1].unit === 0 && updatedItems[updatedItems.length - 1].unitPrice === Utils.currencyFormat(0)){
      updatedItems.pop();   
     }
  
    this.setState({ additionalMaterialItems: updatedItems }, () => {
      this.updateRowSelectionCheckJob(e, rowData, row, selected);
    });
  };


  updateRowSelectionCheckJob = (e, rowData, row, selected) => {
    console.log("job----", this.state.jobdetailsdata);


    console.log("row select", row, rowData);
    console.log("selected", row.qtyOrdered);
    console.log("material select", this.state.additionalMaterialItems);

    let JODetails = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.JODetails))
    );


    JODetails.forEach(jobDetail => {
      if (jobDetail.jobid === row.jobid) {
        jobDetail.isChecked = true;
      }
    });





    const newMaterialItem = {
      ...row,
      srno: selected === "true" ? this.state.additionalMaterialItems.length + 1 : this.state.additionalMaterialItems.length + rowData + 1,
      defaultitemname: [{
        itemName: `${row.processname}\n${row.jdescription}`,
        label: row.processname,
        unitPrice: row.unitPrice,
        qty: row.qtyOrdered,
        id: row.id,
      }],
      defaultjobno: [{
        jobNo: row.jobNo,
        label: row.jobNo,
        jobId: row.jobid
      }],
      defaultcustomerPoNumber: [{
        customerPoNumber: row.customerPoNumber,
        label: row.customerPoNumber,
        id: row.id
      }],
      JODetails: JODetails,
      qty: row.qtyOrdered,
      jobid: row.jobid,
      jobdetailId:  row.jobdetailId==undefined ? row.jobdetailid : row.jobdetailId,
      selectedIDs: [{
        id: 0,
        jobid: row.jobid,
        jobno: row.jobNo
      }]
    };



    this.setState(prevState => {
      const updatedItems = [...prevState.additionalMaterialItems];
      updatedItems.push(newMaterialItem);

      return {
        additionalMaterialItems: updatedItems
      };
    });



  };



  handleOnSelect = (row, isSelect) => {

    let jobOrderlistByItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobOrderlistByItem))
    );

    let listItem = jobOrderlistByItem.filter(
      (x) => x.rowId === row.rowId
    );

    listItem[0].selected = isSelect;

    this.setState({ jobOrderlistByItem: jobOrderlistByItem });


  };

  handleOnSelectAll = (isSelect, rows) => {

    const ids = rows.map(r => r.rowId);

    let jobOrderlistByItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobOrderlistByItem))
    );

    let listItems = jobOrderlistByItem.filter(
      (x) => ids.includes(x.rowId)
    );

    if (listItems.length > 0) {
      listItems.forEach((element: any, index: any) => {
        element.selected = isSelect;
      });
    };

    this.setState({ jobOrderlistByItem: jobOrderlistByItem });
  };

  handleOnSelectForJob = (row, isSelect) => {

    let jobdetailsItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobdetailsdata))
    );

    let listItem = jobdetailsItem.filter(
      (x) => x.id === row.id
    );

    listItem[0].selected = isSelect;

    this.setState({ jobdetailsdata: jobdetailsItem });


  };

  handleOnSelectAllForJob = (isSelect, rows) => {

    const ids = rows.map(r => r.id);

    let jobdetailsItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.jobdetailsdata))
    );

    let listItems = jobdetailsItem.filter(
      (x) => ids.includes(x.id)
    );

    if (listItems.length > 0) {
      listItems.forEach((element: any, index: any) => {
        element.selected = isSelect;
      });
    };

    this.setState({ jobdetailsdata: jobdetailsItem });
  };

  showSlideOut = (row, type) => {
    if (type === 'customer') {
      this.setState({ showCustomerSlideout: true, isStateChanged: false, showJobSlideout: false, orderid: row.customerOrderId ? row.customerOrderId : row.orderId });
    }
    else if (type === 'job') {

      let itemNo = Utils.extractNumberAfterHyphen(row.jobNo);
      this.setState({ showJobSlideout: true, showCustomerSlideout: false, isStateChanged: false, orderdetailid: row.customerOrderDetailId ? row.customerOrderDetailId : row.orderDetailId, jobid: row.jobid, qtyOrdered: row.qty, partname: row.partName, partNo: row.partNo, selectedCustomer: row.customerName, productid: row.partId, CustomerPoNumber: row.customerPoNumber, itemNo: itemNo });
    }
  };

  handleClose = () => {
    this.setState({ showAfterSaveModelPopup: false, isSpinner: false, });
  };

  actionclick = (eventKey: any) => {
    if (eventKey == "Delete") {
      // this.deleteVendorOrder();
      let masg = "";

      masg = "Are you sure you want to delete this vendor order?";

      confirmAlert({
        title: "Delete Order",

        message: masg,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.deleteVendorOrder(),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],

        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
    else if (eventKey == "Print") {

      if (this.state.isStateChanged == true) {

        this.saveOrderfinal("order", false, 1);

      }
      else {
        this.printOrder();
      }
    }
    else if (eventKey == "Email") {

      if (this.state.isStateChanged == true) {

        this.saveOrderfinal("order", false, 2);

      }
      else {
        this.mailOrder();
      }

    }
    else {
      this.props.reopenSlideOutWithReworkJob();
    }
  };

  ConfirmSendtoBack = () => {

    let masg = "";

    masg = "Are you sure you want to send  back to receiving?";

    confirmAlert({
      title: "Send back to Receiving",

      message: masg,
      buttons: [
        {
          label: "Yes",
          onClick: () => this.sendBackToReceiving(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],

      closeOnEscape: false,
      closeOnClickOutside: false,
    });

  };

  sendBackToReceiving = () => {

    let request: any = {};
    let receivingData: any = [];
    let vendorDetails: any = [];

    // if(this.state.vendorordertype === 'Material')
    // {
    //   vendorDetails = this.state.jobOrderlistByItem;
    // }
    // else if(this.state.vendorordertype === 'Miscellaneous')
    //   {
    //     vendorDetails = this.state.misclistByItem;
    //   }
    // else{
    //   vendorDetails = this.state.jobdetailsdata;
    // }
    //   vendorDetails= this.state.vendorOrderToUpdateList;
    //   vendorDetails.forEach(element => {
    // 
    let item: any = {};
    //     if(element.id > 0)
    //     {
    item.id = -1;
    item.received = '';
    item.qtyReceived = 0;
    item.vendorOrderType = this.state.vendorordertype;
    item.orderID = this.state.formData.OrderID;
    receivingData.push(item);
    //   }
    // });

    this.state.formData.ReceivingData = receivingData;
    request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));

    VendorService.SaveReceivingData(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          this.closeSlideOut();
          toast.success("Order has been updated.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
        }
        resolve();
        this.setState({ isStateChanged: false, isSpinner: false });
      })
      .catch((error) => {
        this.setState({ isdefaultColDisabled: false, isSpinner: false, isStateChanged: false });
        reject();
      });

  };


  printOrder = () => {
    this.downloadVendorOrderFile(null);
    this.setState({ showAfterSaveModelPopup: false });
  };

  mailOrder = () => {
    // this.SendMailToVendor();
    this.setState({ showAfterSaveModelPopup: false, showMailPanel: true });
  };

  sendMail = () => {

    this.setState({ showMailPanel: false });
    this.SendMailToVendor();
    //this.setState({ showAfterSaveModelPopup: false });
  };

  cancelSendMail = () => {
    this.setState({ showMailPanel: false });
  };

  handleNewRowitems = () => {
    let misclistByItem: any = [];
    let newRow: any = {};
    let isStateChanged = true;
    let inventorydata: any = [];
    inventorydata = Object.assign([], JSON.parse(JSON.stringify(this.state.inventorydata)));
    const jodetails = Object.assign([], JSON.parse(JSON.stringify(this.state.JODetails)));

    misclistByItem = Object.assign([], JSON.parse(JSON.stringify(this.state.misclistByItem)));
    newRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.itemdetailsdatanew)));

    let maxValueOfRow = Math.max(...misclistByItem.map(o => o.itemNo), 0);
    let len = misclistByItem.length;

    let maxSrno = len == 0 ? 0 : misclistByItem[len - 1].itemNo;
    newRow.srno = len == 0 ? 1 : (len + 1);
    newRow.itemNo = Number(maxSrno) + 1;
    newRow.unitPrice = 0;
    newRow.itemListdata = inventorydata;
    newRow.JODetails = jodetails;
    misclistByItem.push(newRow);

    this.setState({ misclistByItem: [] }, () => {
      this.setState({ misclistByItem: misclistByItem, isStateChanged });
    });
  };

  handleNewRowAdditionalMaterialItems = () => {

    //let misclistByItem: any = [];
    let newRow: any = {};
    let isStateChanged = true;
    let inventorydata: any = [];
    inventorydata = Object.assign([], JSON.parse(JSON.stringify(this.state.inventorydata)));
    const jodetails = Object.assign([], JSON.parse(JSON.stringify(this.state.JODetails)));
    console.log("additional new row", jodetails);
    let additionalMaterialItems = Object.assign([], JSON.parse(JSON.stringify(this.state.additionalMaterialItems)));
    console.log("upate data add new row add", additionalMaterialItems);
    newRow = Object.assign({}, JSON.parse(JSON.stringify(this.state.itemdetailsdatanew)));

    let maxValueOfRow = Math.max(...additionalMaterialItems.map(o => o.itemNo), 0);
    let len = additionalMaterialItems.length;

    let maxSrno = len == 0 ? 0 : additionalMaterialItems[len - 1].itemNo;
    newRow.srno = len == 0 ? 1 : (len + 1);
    newRow.itemNo = Number(maxSrno) + 1;
    newRow.unitPrice = 0;
    newRow.itemListdata = inventorydata;
    newRow.JODetails = jodetails;
    additionalMaterialItems.push(newRow);

    this.setState({ additionalMaterialItems: [] }, () => {
      this.setState({ additionalMaterialItems: additionalMaterialItems, isStateChanged });
    });
  };

  handleItemDetailDeleteRow(row: any, mode: any) {
    if (mode) {

      let misclistByItem = Object.assign([], JSON.parse(JSON.stringify(this.state.misclistByItem)));

      this.setState({ misclistByItem: [] }, () => {


        const misclistByItemNewc = misclistByItem.filter(item => item.id !== row.id).map((item, index) => {
          //item.templateno = index + 1;
          return item;
        });

        this.setState({
          misclistByItem: misclistByItemNewc
        },
          () => {


          });
      });
    }
  }

  handleAdditionalItemsDeleteRow(row: any, mode: any) {
    if (mode) {

      let additionalMaterialItems = Object.assign([], JSON.parse(JSON.stringify(this.state.additionalMaterialItems)));

      this.setState({ additionalMaterialItems: [] }, () => {


        const additionalMaterialItemsNewc = additionalMaterialItems.filter(item => item.srno !== row.srno).map((item, index) => {
          //item.templateno = index + 1;
          return item;
        });

        this.setState({
          additionalMaterialItems: additionalMaterialItemsNewc, isStateChanged: true
        },
          () => { });
      });
    }
  }

  handleSelectedItem = (control: any, id: any) => {
    console.log("main id", id);
    if (this != undefined) {
      let isStateChanged = true;


      let formData = { ...this.state.formData };
      let controlID = Number(control.split("_")[0]);
      let rowIndex = Number(control.split("_")[1]);


      let defaultitemname: any = [];
      let defaultjobno: any = [];

      let inventorydata = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.inventorydata))
      );

      let misclistByItem = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.misclistByItem))
      );
      let additionalMaterialItems = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.additionalMaterialItems))
      );

      if (controlID === 1) {

        if (id.length > 0) {
          if (id[0].id > 0) {
            misclistByItem[rowIndex].itemName = id[0].selectitem;
            misclistByItem[rowIndex].itemNo = id[0].id;
            misclistByItem[rowIndex].unitPrice = id[0].unitPrice;
            misclistByItem[rowIndex].unit = id[0].unit;
            misclistByItem[rowIndex].glCode = id[0].description;
            misclistByItem[rowIndex].qty = id[0].stockqty;

            misclistByItem[rowIndex].unit = id[0].unit;

            misclistByItem[rowIndex].itemDesc = id[0].description;

            let objPartname: any = {};
            objPartname.itemName = id[0].selectitem;
            objPartname.label = id[0].selectitem;
            objPartname.id = id[0].id;
            objPartname.glCode = id[0].glcode;
            objPartname.qty = id[0].stockqty;
            objPartname.unitPrice = id[0].unitPrice;
            objPartname.unit = id[0].unit;
            objPartname.tenantid = 0;

            objPartname.itemDesc = id[0].description;
            defaultitemname.push(objPartname);

            misclistByItem[rowIndex].defaultitemname = defaultitemname;
            this.setState(
              { misclistByItem, defaultitemname, inventorydata, isStateChanged },
              () => {



                // 
                // let txtID ="rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex +"ddl";
                // $("input[class^='" + txtID + "']").focus();

              }
            );
            // this.setState(
            //   { itemdetailsdata, defaultitemname, inventorydata, isStateChanged },
            //   () => {

            //     let txtID = "txtglcode" + rowIndex + "txt";
            //     $("#tbltemplate")
            //       .find("input[id^='" + txtID + "']")
            //       .focus();
            //   }
            // );
          } else {
            misclistByItem[rowIndex].itemName = id[0].label;
            misclistByItem[rowIndex].id = 0;
            misclistByItem[rowIndex].glCode = "";
            misclistByItem[rowIndex].qty = 0;
            misclistByItem[rowIndex].unit = "";
            misclistByItem[rowIndex].unitPrice = 0;

            misclistByItem[rowIndex].itemDesc = "";

            let objPartname: any = {};
            objPartname.itemName = id[0].label;
            objPartname.label = id[0].label;
            objPartname.id = 0;
            objPartname.glCode = "";
            objPartname.unit = "";
            objPartname.qty = 0;

            objPartname.tenantid = 0;

            objPartname.unitPrice = 0;

            objPartname.itemDesc = "";
            defaultitemname.push(objPartname);
            inventorydata.push(objPartname);


            misclistByItem[rowIndex].defaultitemname = id;
            this.setState(
              { misclistByItem, defaultitemname, inventorydata, isStateChanged },
              () => {



                // 
                // let txtID ="rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex +"ddl";
                // $("input[class^='" + txtID + "']").focus();

              }
            );
            // this.setState(
            //   { itemdetailsdata, defaultitemname, inventorydata, isStateChanged },
            //   () => {

            //     let txtID = "txtglcode" + rowIndex + "txt";
            //     $("#tbltemplate")
            //       .find("input[id^='" + txtID + "']")
            //       .focus();
            //   }
            // );
          }
        } else {

          misclistByItem[rowIndex].itemName = "";
          misclistByItem[rowIndex].id = 0;
          misclistByItem[rowIndex].glCode = "";
          misclistByItem[rowIndex].qty = 0;
          misclistByItem[rowIndex].unitPrice = 0;
          misclistByItem[rowIndex].itemDesc = "";

          let objPartname: any = {};
          objPartname.itemName = "";
          objPartname.label = "";
          objPartname.id = 0;
          objPartname.glCode = "";
          objPartname.unit = "";
          objPartname.qty = 0;
          objPartname.tenantid = 0;

          objPartname.unitPrice = 0;

          objPartname.itemDesc = "";
          defaultitemname.push(objPartname);
          //inventorydata.push(objPartname);

          //element.defaultPartname = defaultPartname;
          misclistByItem[rowIndex].defaultitemname = id;
          this.setState(
            { misclistByItem, defaultitemname, inventorydata, isStateChanged },
            () => {



              // 
              // let txtID ="rbt-input-main form-control rbt-input form-control form-control itemname" + rowIndex +"ddl";
              // $("input[class^='" + txtID + "']").focus();

            }
          );

        }


      }
      else if (controlID === 2) {
        if (id.length > 0) {
          if (id[0].id > 0) {
            additionalMaterialItems[rowIndex].itemName = this.state.vendorordertype === "Material" ? id[0].selectitem: id[0].itemname;
            additionalMaterialItems[rowIndex].itemNo = id[0].id;
            additionalMaterialItems[rowIndex].unitPrice = id[0].unitPrice;
            additionalMaterialItems[rowIndex].unit = id[0].unit;
            additionalMaterialItems[rowIndex].glCode =  this.state.vendorordertype === "Material" ? id[0].description : "";
            additionalMaterialItems[rowIndex].qty = id[0].stockqty;

            additionalMaterialItems[rowIndex].unit = id[0].unit;

            additionalMaterialItems[rowIndex].itemDesc = id[0].description;

            let objPartname: any = {};
            objPartname.itemName = this.state.vendorordertype === "Material" ? id[0].selectitem: id[0].itemname;
            objPartname.label = id[0].selectitem;
            objPartname.id = id[0].id;
            objPartname.glCode = id[0].glcode;
            objPartname.qty = id[0].stockqty;
            objPartname.unitPrice = id[0].unitPrice;
            objPartname.unit = id[0].unit;
            objPartname.tenantid = 0;
            objPartname.itemDesc = id[0].description;
            defaultitemname.push(objPartname);
            additionalMaterialItems[rowIndex].defaultitemname = defaultitemname;

          } else {
            additionalMaterialItems[rowIndex].itemName = id[0].label;
            additionalMaterialItems[rowIndex].id = 0;
            additionalMaterialItems[rowIndex].glCode = "";
            additionalMaterialItems[rowIndex].qty = 0;
            additionalMaterialItems[rowIndex].unit = "";
            additionalMaterialItems[rowIndex].unitPrice = 0;

            additionalMaterialItems[rowIndex].itemDesc = "";

            let objPartname: any = {};
            objPartname.itemName = id[0].label;
            objPartname.label = id[0].label;
            objPartname.id = 0;
            objPartname.glCode = "";
            objPartname.unit = "";
            objPartname.qty = 0;
            objPartname.tenantid = 0;
            objPartname.unitPrice = 0;
            objPartname.itemDesc = "";
            defaultitemname.push(objPartname);
            inventorydata.push(objPartname);
            additionalMaterialItems[rowIndex].defaultitemname = id;

          }
        } else {

          additionalMaterialItems[rowIndex].itemName = "";
          additionalMaterialItems[rowIndex].id = 0;
          additionalMaterialItems[rowIndex].glCode = "";
          additionalMaterialItems[rowIndex].qty = 0;
          additionalMaterialItems[rowIndex].unitPrice = 0;
          additionalMaterialItems[rowIndex].itemDesc = "";

          let objPartname: any = {};
          objPartname.itemName = "";
          objPartname.label = "";
          objPartname.id = 0;
          objPartname.glCode = "";
          objPartname.unit = "";
          objPartname.qty = 0;
          objPartname.tenantid = 0;

          objPartname.unitPrice = 0;

          objPartname.itemDesc = "";
          defaultitemname.push(objPartname);
          additionalMaterialItems[rowIndex].defaultitemname = id;

        }

        this.setState(
          { additionalMaterialItems, defaultitemname, inventorydata, isStateChanged },
          () => {
            let txtID = "txtitemDesc" + rowIndex + "txt";
            $("#tblAdditionalMaterialItems").find("input[id^='" + txtID + "']").focus();
          });


      }
      else if (controlID === 3) {
        console.log("id---", id);

        if (id.length > 0) {
          if (id[0].jobid > 0) {
            // additionalMaterialItems[rowIndex].jobNo = id[0].jobNo;
            // additionalMaterialItems[rowIndex].jobId = id[0].jobid;

            // let objjobno: any = {};
            // objjobno.jobNo = id[0].jobNo;
            // objjobno.label = id[0].jobNo;
            // objjobno.jobId = id[0].jobid;
            // defaultjobno.push(objjobno);

            // additionalMaterialItems[rowIndex].defaultjobno = defaultjobno;

            additionalMaterialItems[rowIndex].defaultjobno = id;

          } else {
            // additionalMaterialItems[rowIndex].jobNo = id[0].label;
            // additionalMaterialItems[rowIndex].jobId = 0;

            // let objPartname: any = {};
            // objPartname.jobNo = id[0].label;
            // objPartname.label = id[0].label;
            // objPartname.jobId = 0;
            // defaultjobno.push(objPartname);
            additionalMaterialItems[rowIndex].defaultjobno = id;

          }
        } else {

          additionalMaterialItems[rowIndex].defaultjobno = [];

          // additionalMaterialItems[rowIndex].jobNo = "";
          // additionalMaterialItems[rowIndex].jobId = 0;

          // let objPartname: any = {};
          // objPartname.itemName = "";
          // objPartname.label = "";
          // objPartname.id = 0;

          // defaultjobno.push(objPartname);
          // additionalMaterialItems[rowIndex].defaultjobno = id;

        }
        this.setState(
          { additionalMaterialItems, isStateChanged },
          () => {
            // let txtID = "txtreqqty" + rowIndex + "txt";
            // $("#tblAdditionalMaterialItems").find("input[id^='" + txtID + "']").focus();
          });
      } else if (controlID === 4) {
        console.log("customerpo", id);

        if (id[0] && id[0].orderid > 0) {

          const orderid = id[0].orderid;
          this.GetJoblistById(orderid);
          additionalMaterialItems[rowIndex].defaultcustomerPoNumber = id;
        } else {
          additionalMaterialItems[rowIndex].defaultcustomerPoNumber = [];
        }
        this.setState(
          { additionalMaterialItems, isStateChanged },
          () => {
            // let txtID = "txtreqqty" + rowIndex + "txt";
            // $("#tblAdditionalMaterialItems").find("input[id^='" + txtID + "']").focus();
          });
      } else if (controlID === 5) {

        if (id.length > 0) {
          if (id[0].jobid > 0) {

            misclistByItem[rowIndex].defaultjobno = id;

          } else {

            misclistByItem[rowIndex].defaultjobno = id;

          }
        } else {

          misclistByItem[rowIndex].defaultjobno = [];

        }
        this.setState(
          { misclistByItem, isStateChanged },
          () => {
            // let txtID = "txtreqqty" + rowIndex + "txt";
            // $("#tblAdditionalMaterialItems").find("input[id^='" + txtID + "']").focus();
          });
      } else if (controlID === 6) {


        if (id[0] && id[0].orderid > 0) {

          const orderid = id[0].orderid;
          this.GetJoblistById(orderid);
          misclistByItem[rowIndex].defaultcustomerPoNumber = id;
        } else {
          misclistByItem[rowIndex].defaultcustomerPoNumber = [];
        }
        this.setState(
          { misclistByItem, isStateChanged },
          () => {
            // let txtID = "txtreqqty" + rowIndex + "txt";
            // $("#tblAdditionalMaterialItems").find("input[id^='" + txtID + "']").focus();
          });
      }
      // this.setState(
      //   { misclistByItem, defaultitemname, inventorydata, isStateChanged },
      //   () => {
      //     let txtID = "txtitemDesc" + rowIndex + "txt";
      //     //$("input[class^='" + txtID + "']").focus();
      //     //$("#tbltemplate").find("input[id^='" + txtID + "']").focus();

      //   }
      // );
    }
  };

  handleOutsideClick = (rowIndex: any, e: any) => {

    let btnId = "itemname" + rowIndex + "btn";
    let id = "itemname" + rowIndex + "ddl";
    let relatedTargetID = e.relatedTarget?.id;

    let defaultitemname: any = [];
    let inventorydata = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.inventorydata))
    );

    let misclistByItem = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.misclistByItem))
    );

    misclistByItem[rowIndex].itemName = e.target.value;
    misclistByItem[rowIndex].id = 0;
    misclistByItem[rowIndex].qty = 0;
    misclistByItem[rowIndex].unitPrice = 0;
    //misclistByItem[rowIndex].itemDesc = "";



    if (e.target.value != "") {
      let objPartname: any = {};
      objPartname.itemname = e.target.value;
      objPartname.label = e.target.value;
      objPartname.id = 0;
      //objPartname.glcode = "";
      objPartname.qty = 0;
      objPartname.unit = "";

      objPartname.tenantid = 0;

      objPartname.unitPrice = 0;

      objPartname.description = "";
      defaultitemname.push(objPartname);
      inventorydata.push(objPartname);




      misclistByItem[rowIndex].defaultitemname = defaultitemname;

      this.setState({ misclistByItem, defaultitemname, inventorydata }, () => {


      });
    }
    else {

      this.setState({ misclistByItem: [] }, () =>
        this.setState({ misclistByItem }, () => {
        })
      );
    }

    $("#tbltemplate")
      .find("button[id^='" + btnId + "']")
      .css("display", "none");
    $("#tbltemplate")
      .find("div[id^='" + id + "']")
      .css("display", "none");
  };


    handleOutsideClickForAdditionalItems = (rowIndex: any, e: any) => {

      let btnId = "itemname" + rowIndex + "btn";
      let id = "itemname" + rowIndex + "ddl";
      let relatedTargetID = e.relatedTarget?.id;

      let defaultitemname: any = [];
      let inventorydata = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.inventorydata))
      );

      let additionalMaterialItems = Object.assign(
        [],
        JSON.parse(JSON.stringify(this.state.additionalMaterialItems))
      );

      additionalMaterialItems[rowIndex].itemName = e.target.value;
      additionalMaterialItems[rowIndex].id = 0;
      additionalMaterialItems[rowIndex].qty = 0;
      additionalMaterialItems[rowIndex].unitPrice = 0;
      //misclistByItem[rowIndex].itemDesc = "";



      if (e.target.value != "") {
        let objPartname: any = {};
        objPartname.itemName = e.target.value;
        objPartname.label = e.target.value;
        objPartname.itemDesc = "",
        objPartname.id = 0;
        //objPartname.glcode = "";
        objPartname.qty = 0;
        objPartname.unit = "";

        objPartname.tenantid = 0;

        objPartname.unitPrice = 0;

        objPartname.description = "";
        defaultitemname.push(objPartname);
        inventorydata.push(objPartname);




        additionalMaterialItems[rowIndex].defaultitemname = defaultitemname;

        this.setState({ additionalMaterialItems, defaultitemname, inventorydata }, () => {


        });
      }
      else {

        this.setState({ additionalMaterialItems: [] }, () =>
          this.setState({ additionalMaterialItems }, () => {
          })
        );
      }

      $("#tblAdditionalMaterialItems")
        .find("button[id^='" + btnId + "']")
        .css("display", "none");
      $("#tblAdditionalMaterialItems")
        .find("div[id^='" + id + "']")
        .css("display", "none");
    };

  itemsSelected = (Chieldstate: any) => {
    let data = Object.assign([], JSON.parse(JSON.stringify(Chieldstate)));
    this.setState({ selectedItems: data, isChange: true });
  };


  render() {
    const {

    } = this.state;


    const expandSplitRowForSelected = {
      onlyOneExpanding: false,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderItemTableChild(row, rowIndex, true),
      showExpandColumn: true,
      expandByColumnOnly: true,
      onExpandAll: (isExpandAll, results, e) => {
        // ...
      },
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const expandSplitRow = {
      onlyOneExpanding: false,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderItemTableChild(row, rowIndex, false),
      showExpandColumn: true,
      expandByColumnOnly: true,
      onExpandAll: (isExpandAll, results, e) => {
        // ...
      },
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };
    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };

    const renderItemTableChild = (mainrow, rowIndex, showSelected = true) => {

      let splitDetail: any = [];
      splitDetail = this.state.jobOrderlistByItem.filter(
        (x) => x.itemId === mainrow.itemId && x.rowType === 2 && (x.selected === showSelected || this.state.vendororderid == 0)
      );


      const columnCOAChild = [
        {
          dataField: "isSelected",
          text: "",
          headerFormatter: this.icheckselectedall,
          classes: "expanding-bar",
          formatter: (cell: any, row: any, rowIndex: any) => {
            const prevOrderidselected = mainrow.orderid;
            this.setState({ prevOrderidselected: prevOrderidselected });
            return (
              <div className="d-flex align-items-center txt-bold">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="toolp">Click to copy this part</Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    size="xs"
                    onClick={(e) =>
                      this.handleRowSelectionCheck(
                        e,
                        rowIndex,
                        row,
                        "true"
                      )
                    }
                  />
                </OverlayTrigger>
              </div>
            );
          },
        },
        {
          dataField: "JobNo",
          text: "Job No",

          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                <Button className="btn-link" onClick={() => this.showSlideOut(row, 'job')}>
                  {row.jobNo}
                </Button>
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "itemDesc",
          text: "Item Description",
          headerClasses: "coa",
          hidden: false,
          headerStyle: { width: "400px", textAlign: "left" },
          headerAttrs: (column, colIndex) => ({ tabIndex: "-1" }),
          formatter: (cell: any, row: any, rowIndex: any) => {
            let id = "txtitemDesc" + rowIndex + "txt";
            return (
              <div>
                <Form.Control
                  tabIndex={this.state.tabEnable}
                  id={id}
                  type="text"
                  placeholder="Please enter item details"
                  value={row.itemDesc}
                  onChange={(e) => this.updateState(e, row, "itemDesc", id)}
                  autoComplete="off"
                  //pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={200}
                  disabled={false}
                  title=""
                />
              </div>
            );
          },
        },
        {
          dataField: "partName",
          text: "Part Description",
          classes: "text-truncate",
          headerStyle: { width: "10%" },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (

              <div className="d-flex align-items-center txt-bold">
                <div style={{ width: '150px' }}>
                  <EllipsisWithTooltip placement="bottom">
                    {row.partName}
                  </EllipsisWithTooltip>
                </div>
              </div>
            );
          },
        },
        {
          dataField: "partNo",
          text: "Part No",

          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.partNo}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "customerPoNumber",
          text: "CO #",
          hidden: false,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                <Button className="btn-link" onClick={() => this.showSlideOut(row, 'customer')}>
                  {row.customerPoNumber}
                </Button>

              </EllipsisWithTooltip>

            );
          },
        },
        {
          dataField: "qty",
          text: "Qty",
          hidden: false,
          headerStyle: { width: "70px" },
          formatter: (cell: any, row: any, rowIndex: any) => {
            let id = "txtexpqty" + rowIndex + "txt";
            let disabled = false;
            //this.props.receivedStatus !== 'Completed' && this.props.receivedStatus !== 'Received' ? false:true;
            return (
              <div>
                <Form.Control
                  tabIndex={this.state.tabEnable}
                  id={id}
                  type="text"
                  // placeholder="Enter Qty"
                  value={row.qty}
                  onChange={(e) => this.updateState(e, row, "expandqty", id)}
                  autoComplete="off"
                  pattern="[+-]?\d+(?:[.,]\d+)?"
                  maxLength={20}
                  disabled={disabled}
                  title=""
                />
              </div>
            );
          },
        },

        {
          dataField: "unit",
          text: "Unit",
          hidden: false,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.unit}
              </EllipsisWithTooltip>
            );
          },
        },

        {
          dataField: "unitPrice",
          text: "Unit Price",
          hidden: false,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.unitPrice}
              </EllipsisWithTooltip>
            );
          },
        },

        {
          dataField: "jobId",
          text: "JobId",
          hidden: true,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.jobId}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "ItemId",
          text: "ItemId",
          hidden: true,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.itemId}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "ItemId",
          text: "ItemId",
          hidden: true,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {row.itemId}
              </EllipsisWithTooltip>
            );
          },
        },
        {
          dataField: "",
          text: "",
          headerClasses: "blankLastChildofChild",
        },


      ];

      return (
        <>
          <div className='px-4 p-4'>
            <BootstrapTable
              id="tblCOAchild"
              keyField="rowId"
              data={splitDetail}
              columns={columnCOAChild}
            //rowEvents={this.rowEvents}
            // selectRow={{
            //   mode: 'checkbox', clickToSelect: false, hideSelectAll: false,
            //   selected: splitDetail.filter((x) => x.selected === true).map(r => r.rowId),
            //   onSelect: this.handleOnSelect,
            //   onSelectAll: this.handleOnSelectAll,
            //   hideSelectColumn: this.state.vendororderid > 0 && !this.state.editMode
            // }}

            />
          </div>
        </>
      );
    };

    return (
      //  <div className="transaction-slideout invoice-entry pos-fxd index-1000">

      <section>
        <div className="details-drawer"
          style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
        // className="back-drop1  viewer-panel1 opacity "
        >
          <ToastContainer
            containerId={"userDetailSlideoutcontainer"}
            autoClose={3000}
          />

          <div className="d-flex">
            {!this.state.showMailPanel && <div
              className="back-drop1  viewer-panel1 opacity"
              onClick={() => this.closeSlideOut()}
            >
            </div>}

            {this.state.showMailPanel &&
              <div
                className="back-drop1  viewer-panel1 opacity z-2 position-absolute"
                style={{ minWidth: "100 %", width: "992px", maxWidth: "100%" }}
              >

                <div
                  className="back-drop viewer-panel bg-white z-3 position-absolute"
                  //className="body-sec viewer-panel back-drop p-0 z-3"
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
                  <div
                    className="scroll p-3"
                  >
                    <Form>


                      <div className="row my-2">
                        <div className="col-2 ml-3">
                          <Form.Label className="h6 font-semibold ">Mail To</Form.Label>
                        </div>

                        <FormGroup controlId="mailTo">
                          <div className="col-12 ml-3">
                            <Form.Control type="text" placeholder="MailTo" value={this.state.mailContent.MailTo}
                              onChange={(event: any) => { this.handleChange(event, "MailTo", 0); }}

                            ></Form.Control>
                          </div>
                        </FormGroup>
                      </div>



                      <div className="row my-2">
                        <div className="col-2 ml-3">
                          <Form.Label className="h6 font-semibold">CC</Form.Label>
                        </div>

                        <FormGroup controlId="customerName">
                          <div className="col-12 ml-3">
                            <Form.Control type="text" placeholder="Email" value={this.state.mailContent.CC}
                              onChange={(event: any) => this.handleChange(event, "CC", 0)}
                            ></Form.Control>
                          </div>
                        </FormGroup>
                      </div>




                      <div className="row my-2 mb-5">
                        <div className="col-2 ml-4">
                          <Form.Label className="h6 font-semibold">Message</Form.Label>
                        </div>

                        <FormGroup controlId="customerName">
                          <div className="col-12 ml-4">
                            <Form.Control type="text" placeholder="Message"
                              as="textarea"
                              wrap="hard"
                              rows={15}
                              value={this.state.mailContent.Message}
                              onChange={(event: any) => this.handleChange(event, "Message", 0)}
                            ></Form.Control>
                          </div>
                        </FormGroup>
                      </div>






                    </Form>
                  </div>
                  <div className={"footer-section viewer-panel"}
                    style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                  >
                    <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                      <div className="d-flex gap-3 align-items-center">
                        <Button
                          id="btnCancelSendMail"
                          variant="primary"
                          type="button"
                          className="space btn btn-primary btn-discard"

                          onClick={() => this.cancelSendMail()}

                        >
                          {"Cancel"}
                        </Button>
                        <Button
                          id="btnSendMail"
                          variant="primary"
                          type="button"
                          onClick={() => this.sendMail()}

                        >
                          {"Send"}
                        </Button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            }

            <Container
              fluid

              className="body-sec viewer-panel back-drop p-0 "
              style={{ minWidth: "75%", width: "892px", maxWidth: "100%" }}

            >
              <div
                className="container-fluid py-1 bg-white d-flex align-items-center round-4"
              >
                <div className="divheader">
                  <h5 className="my-title">


                    {this.state.vendororderid == 0 && <span>New Vendor Order</span>}
                    {this.state.vendororderid != 0 && (
                      <span>Vendor Order No : {this.props.CustomerPoNumber ? this.props.CustomerPoNumber : this.state.formData.CustomerPoNumber}</span>
                    )}
                  </h5>


                  <div className="header-popout ml-auto">

                    <div className="container text-center">
                      <div className="row align-items-start">
                        <div className="col d-none">

                          {this.state.vendororderid == 0 && <BootstrapSwitchButton width={150} height={40} offstyle="success"
                            checked={this.state.vendorordertype !== 'Process'}
                            onlabel='Material'
                            offlabel='Process'
                            disabled={this.state.vendororderid > 0}
                            onChange={(checked: boolean) => {
                              this.setState({ vendorordertype: (checked ? 'Material' : 'Process') });
                            }}
                          />}


                        </div>

                        <div className="col">
                          {this.state.vendororderid != 0
                            //  && this.state.jobTrackerId === 0 
                            //                             && !this.state.processVendorPOCreated && !this.state.materialVendorPOCreated
                            &&

                            <div className="action">

                              <Dropdown
                                className="more-action"

                                onSelect={(event: any) => { this.actionclick(event); }}
                              >
                                <Dropdown.Toggle
                                  className="btn-outline-primary btn btn-primary more"
                                  id="dropdown-more"
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>



                                  <Dropdown.Item

                                    eventKey={"Print"}
                                    key={1}
                                  >
                                    {"Print"}
                                  </Dropdown.Item>
                                  <Dropdown.Item

                                    eventKey={"Email"}
                                    key={1}
                                  >
                                    {"Email"}
                                  </Dropdown.Item>
                                  <Dropdown.Item

                                    eventKey={"Delete"}
                                    key={1}
                                  >
                                    {"Delete"}
                                  </Dropdown.Item>

                                </Dropdown.Menu>
                              </Dropdown>



                            </div>


                          }
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-primary cross"
                            onClick={this.closeSlideOut}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                fill="#ffffff"
                              />
                            </svg>
                          </button>

                        </div>

                      </div>
                    </div>


                    {/* <button
                      type="button"
                      className="btn wht-bg cross"
                      onClick={this.closeSlideOut}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                          fill="#84888C"
                        />
                      </svg>
                    </button> */}
                  </div>
                </div>
              </div>
              <div
                className="scroll"
              >

                <section className="pb-3">
                  <div className="row g-3">

                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="customerName">
                        <div className="parent my-data">
                          <Form.Label className="font-semibold">
                            Vendor Name
                          </Form.Label>
                          <div
                            tabIndex={this.state.tabEnable}
                            className="type-head"
                          >
                            <VendorSearch
                              ref={this.customerSearch}
                              updateCustomerList={this.updateCustomerList}
                              handleVendorChange={
                                this.handleVendorChange
                              }
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-3" >
                      <Form.Group controlId="buyerName">
                        <div className="parent my-data">
                          <Form.Label className="font-semibold">
                            Contact Name
                          </Form.Label>
                          <div
                            tabIndex={this.state.tabEnable}
                            className="type-head"
                          >    <Typeahead

                              id="selections-example"
                              labelKey="name"
                              // onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                              //   this.state.formData.firstname = text;
                              // }}
                              onInputChange={(text: string, e: ChangeEvent<HTMLInputElement>) => {
                                // console.log(text, e);
                                this.state.formData.firstname = text;
                              }}
                              onChange={(e) => this.handleChangeBuyer(e)}
                              options={this.state.options}
                              placeholder="Select contact details..."
                              selected={this.state.Defaultselected}
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-2" >
                      <Form.Group controlId="vendorName">
                        <div className="parent my-data">
                          <Form.Label className="font-semibold">
                            Order Date
                          </Form.Label>
                          <div className="date-picker calender-picker">
                            {/* <Form.Control type="text" id="txtOrderDate"
                                                             value={this.state.formData.OrderDate}
                                                             onChange={(event: any) => this.handleChange(event, 'orderDate')} 
                                                            autoComplete='off'
                                                            title=""

                                                        /> */}
                            {/* {  <div className="drop-arrow" onClick={(event: any) => this.handleDatePicker(event, this.trDate)}>
                                                            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                                            </svg>
                                                        </div> } */}

                            <DatePicker
                              ref={this.trDate}
                              id="txtOrderDate"
                              tabIndex={this.state.tabEnable}
                              className="form-control px-0 w-75 text-center pe-5"
                              placeholderText={"MM/DD/YY"}
                              dateFormat="MM/dd/yy"
                              selected={
                                this.state.formData.OrderDate === null
                                  ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
                                  : this.state.formData.OrderDate === ""
                                    ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
                                    : new Date(this.state.formData.OrderDate)
                              }
                              onChange={(event: any) =>
                                this.handleChange(event, "orderDate", 0)
                              }
                              // onKeyDown={(e: any) =>
                              //   this.onTAB("txtOrderDate-1", 0, e)
                              // }
                              onClick={(e: any) =>
                                this.onTAB("txtOrderDate-1", 0, e)
                              }
                              autoComplete="off"
                            // disabled={isBedgeProcessing && true}
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-2" >
                      <Form.Group controlId="vendorName">
                        <div className="parent my-data">
                          <Form.Label className="font-semibold">
                            Due Date
                          </Form.Label>
                          <div className="date-picker calender-picker">
                            <DatePicker
                              id="txtDueDate"
                              tabIndex={this.state.tabEnable}
                              className="form-control px-0 w-75 text-center pe-5"
                              placeholderText={"MM/DD/YY"}
                              dateFormat="MM/dd/yy"
                              selected={
                                this.state.formData.duedate === null
                                  ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
                                  : this.state.formData.duedate === ""
                                    ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
                                    : new Date(this.state.formData.duedate)
                              }
                              onChange={(event: any) =>
                                this.handleChange(event, "dueDate", 0)
                              }
                              onClick={(e: any) =>
                                this.onTAB("txtDueDate-1", 0, e)
                              }
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-12 col-md-2" >
                      <Form.Group controlId="customerName">
                        <div className="parent my-data">
                          <Form.Label className="font-semibold">
                            Ship Via
                          </Form.Label>
                          <div
                            tabIndex={this.state.tabEnable}
                            className="type-head"
                          >
                            <Form.Control
                              tabIndex={this.state.tabEnable}
                              id="txtShipVia"
                              type="text"
                              placeholder="Enter Ship Via"
                              value={this.state.formData.ship_via}
                              onChange={(event: any) => this.handleChange(event, 'ship_via', 0)}
                              autoComplete="off"

                              disabled={false}
                              title=""
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </div>




                  </div>
                  <div className="col-12 col-md-2 mt-2" >
                    <Form.Group controlId="customerName">
                      <div className="parent my-data">
                        <Form.Label className="font-semibold">
                          Type
                        </Form.Label>
                        <div
                          tabIndex={this.state.tabEnable}
                          className="type-head"
                        >

<ButtonGroup>
          <ToggleButton
            key={1}
            id={`radio-material`}
            type="radio"
            variant={1 % 2 ? 'outline-primary' : 'secondary'}
            name="radio"
            value="Material"
            checked={this.state.vendorordertype === "Material"}
            onChange={(e) => this.handleType(e.currentTarget.value)}
          >
            Material
          </ToggleButton>
           <ToggleButton
           key={2}
           id={`radio-process`}
           type="radio"
           variant={2 % 2 ? 'secondary' : 'outline-primary'}
           name="radio"
           value="Process"
           checked={this.state.vendorordertype === "Process"}
           onChange={(e) => this.handleType(e.currentTarget.value)}
         >
           Process
         </ToggleButton>
      </ButtonGroup>



                          {/* <SingleDropdownListWithoutSearchBoot
                            itemList={[{ id: "Material", name: "Material" }, { id: "Process", name: "Process" }
                              // , { id: "Miscellaneous", name: "Miscellaneous" }

                            ]}
                            handleSelectedItem={this.handleType}
                            defaultText={"Select"}
                            defaultName={this.state.vendorordertype || "Select"}
                            controlID="1"
                          /> */}

                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </section>

                {/* <Tab.Container transition={false} defaultActiveKey={this.state.vendororderid == 0 ? "first" : this.state.vendorordertype == "Process" ? "second" : this.state.vendorordertype == "Miscellaneous" ? "third" : "first"}  > */}
                {/* {this.state.vendororderid == 0 &&
                    <section className="pb-3">
                      <Nav className="nav nav-underline border-bottom border-top">
                        <Nav.Item className="nav-item">
                          <Nav.Link eventKey="first" onClick={
                            () => this.setState({ vendorordertype: 'Material' })
                          }
                            disabled={this.state.vendororderid == 0 ? false : true}
                          >Materials


                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item">
                          <Nav.Link eventKey="second"
                            onClick={
                              () => this.setState({ vendorordertype: 'Process' })

                            }
                            disabled={this.state.vendororderid == 0 ? false : true}
                          >Process

                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item">
                          <Nav.Link eventKey="third"

                            onClick={
                              () => this.setState({ vendorordertype: 'Miscellaneous' })

                            }
                            disabled={this.state.vendororderid == 0 ? false : true}
                          >Miscellaneous

                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </section>

                  } */}


                {/* <Tab.Content>
                    <Tab.Pane eventKey="first"> */}
                {this.state.vendorordertype === "Material" && (
                  <div
                    className="scroll p-0"
                  >

                    <section className="pb-3">

                      <div className="card">
                        <div className="card-header fw-bold">
                          Order Details

                        </div>
                        <div className="card-body">
                          <div
                            //   className="transactions-list inpute"
                            //  className="table table-borderless align-middle  transactions-list"
                            className="table table-borderless align-middle transactions-list"
                          >
                            {this.state.inventorydata.length > 0 && this.state.jobList.length > 0 && (
                              <div className="split-assign-coa">
                                <BootstrapTable
                                  id="tblAdditionalMaterialItems"
                                  keyField="templateid"
                                  data={this.state.additionalMaterialItems}
                                  columns={this.state.AdditionalMaterialItemColumns}
                                />
                              </div>
                            )}
                          </div>

                          <div className="d-flex action-btn align-items-center">

                            {true &&
                              <Button
                                type="button"
                                className="btn-ghost mr-auto"
                                onClick={this.handleNewRowAdditionalMaterialItems}
                              >

                                <FaCirclePlus className="align-middle lh-1`" /> <span className="align-middle lh-1">Add Item</span>
                              </Button>}
                          </div>
                        </div>

                      </div>

                      {/* <div className="job-link my-2" 
                            onClick={() => { 
                              this.setState(prevState => ({ showSection: !prevState.showSection })); 
                            }}                          >
                          Select from Job Requests<span className="mx-2">            
                            {this.state.showSection ? <FiChevronUp /> : <FiChevronDown />}
                          </span>
                          
                          </div> */}
                      {this.state.showOptionSelect && (
                        <div className="d-inline-block mt-2">
                           <span className="font-semibold" style={{color:"#2caf92",marginRight:"3px"}}>+</span>
                          <a onClick={() => {
                            this.setState(prevState => ({ showSection: !prevState.showSection }));
                          }} className="orderLinks" >{this.state.isprevious == false ? "Select from Job Requests" : "Select from Job Requests"}   </a>

                        </div>
                      )}
                      {this.state.showSection && (
                        <>

                          {/* <div className="job-options">
                          <div className="job-option">
                          <span className="px-2 py-2" style={{backgroundColor:"#EBF0F4",borderRadius:"5px"}}>Material</span>
                          </div>
                          </div> */}


                          <section className="mt-4" style={{ marginBottom: '100px' }}>
                            <div className="card">
                              <div className="card-header fw-bold">
                                {/* Order Details - By Job No. */}
                                Select Materials

                              </div>
                              <div className="card-body">
                                <div
                                  className="table-responsive table table-borderless align-middle transactions-list mb-20"

                                >
                                  {this.state.transactionsColumns.length > 0 && this.state.vendorordertype !== 'Process' && !this.state.editMode && (
                                    <div style={{ backgroundColor: 'white' }} >
                                      <BootstrapTable
                                        id="tblOrder"
                                        keyField="rowId"
                                        data={this.state.jobOrderlistByItem.filter(
                                          (x) => x.rowType === 1 && this.state.jobOrderlistByItem.filter(
                                            (a) => a.itemId === x.itemId && a.rowType === 2 && (a.selected === true || this.state.vendororderid == 0)
                                          ).length > 0
                                        )}
                                        columns={this.state.transactionsColumns}
                                        expandRow={expandSplitRowForSelected}

                                      // rowEvents={this.rowEvents1}
                                      />
                                    </div>
                                  )}



                                  {this.state.editMode && this.state.transactionsColumns.length > 0 && this.state.vendorordertype !== 'Process' && (
                                    <div style={{ backgroundColor: 'white', marginBottom: '80px' }} >
                                      <div className="font-semibold mb-2 mt-1">

                                        Select New Items

                                      </div>
                                      <BootstrapTable
                                        id="tblOrderRaw"
                                        keyField="rowId"
                                        data={this.state.jobOrderlistByItem.filter(
                                          (x) => x.rowType === 1 && this.state.jobOrderlistByItem.filter(
                                            (a) => a.itemId === x.itemId && a.rowType === 2 && (a.selected === false || this.state.vendororderid == 0)
                                          ).length > 0
                                        )}
                                        columns={this.state.transactionsColumns}
                                        expandRow={expandSplitRow}

                                      // rowEvents={this.rowEvents1}
                                      />
                                    </div>
                                  )}



                                </div>
                              </div>
                            </div>
                          </section>
                        </>
                      )}
                    </section>
                  </div>
                )}

                {/* </Tab.Pane>

                    <Tab.Pane eventKey="second"> */}
                {this.state.vendorordertype === "Process" && (
                  <div
                    className="scroll p-0"
                  >
                    <section className="pb-3">

                      <div className="card">
                        <div className="card-header fw-bold">
                          Order Details
                        </div>

                        <div className="card-body">
                          <div
                            //   className="transactions-list inpute"
                            //  className="table table-borderless align-middle  transactions-list"
                            className="table table-borderless align-middle transactions-list"
                          >
                            {this.state.inventorydata.length > 0 && this.state.jobList.length > 0 && (
                              <div className="split-assign-coa">
                                <BootstrapTable
                                  id="tblAdditionalMaterialItems"
                                  keyField="templateid"
                                  data={this.state.additionalMaterialItems}
                                  columns={this.state.AdditionalMaterialItemColumns}
                                />
                              </div>
                            )}
                          </div>

                          <div className="d-flex action-btn align-items-center">

                            {true && 
                              <Button
                                type="button"
                                className="btn-ghost mr-auto"
                                onClick={this.handleNewRowAdditionalMaterialItems}
                              >

                                <FaCirclePlus className="align-middle lh-1`" /> <span className="align-middle lh-1">Add Item</span>
                              </Button>}
                          </div>
                        </div>

                      </div>

                      {/* <div className="job-link my-2" 
                            onClick={() => { 
                              this.setState(prevState => ({ showSection: !prevState.showSection })); 
                            }}                          >
                          Select from Job Requests<span className="mx-2">
                          {this.state.showSection ? <FiChevronUp /> : <FiChevronDown />}
                          </span>
                          
                          </div> */}
                      {this.state.showOptionSelect && (
                        <div className="d-inline-block mt-2">
                         <span className="font-semibold" style={{color:"#2caf92",marginRight:"3px"}}>+</span>
                          <a onClick={() => {
                            this.setState(prevState => ({ showSection: !prevState.showSection }));
                          }} className="orderLinks" >{this.state.isprevious == false ? "Select from Job Requests" : "Select from Job Requests"}   </a>

                        </div>
                      )}
                      {this.state.showSection && (
                        <>

                      

                          <section className="mt-4" style={{ marginBottom: '100px' }}>
                            <div className="card">
                              <div className="card-header fw-bold">
                                {/* Order Details */}
                                Select Processes
                              </div>
                              <div className="card-body">

                                <div
                                  className="table-responsive table table-borderless align-middle transactions-list mb-20"

                                >


                                  {this.state.templateColumns.length > 0 && this.state.vendorordertype === 'Process' && (
                                    <div style={{ backgroundColor: 'white' }} className="split-assign-coa">
                                      <BootstrapTable
                                        id="tbltemplate"
                                        keyField="id"
                                        data={this.state.jobdetailsdata.filter((x) => x.selected === true || this.state.editMode || this.state.vendororderid == 0)}
                                        columns={this.state.templateColumns}
                                      // selectRow={{
                                      //   mode: 'checkbox', clickToSelect: false, hideSelectAll: false, selected: this.state.jobdetailsdata.filter((x) => x.selected === true).map(r => r.id),
                                      //   //selected: this.state.selected,
                                      //   onSelect: this.handleOnSelectForJob,
                                      //   onSelectAll: this.handleOnSelectAllForJob,
                                      //   hideSelectColumn: this.state.vendororderid > 0 && !this.state.editMode
                                      // }}
                                      />
                                    </div>
                                  )}

                                </div>
                              </div>
                              <div className="filter-sec height-172">

                                <br></br>
                                <br></br>

                              </div>
                            </div>

                          </section>
                        </>
                      )}
                    </section>
                  </div>
                )}
                {/* </Tab.Pane>
                    <Tab.Pane eventKey="third"> */}
                {this.state.vendorordertype === "Miscellaneouss" && (
                  <div
                    className="scroll p-0"
                  >
                    <section className="pb-3">

                      <div className="card">
                        <div className="card-header fw-bold">
                          Order Details

                        </div>
                        <div className="card-body">
                          <div
                            //   className="transactions-list inpute"
                            //  className="table table-borderless align-middle  transactions-list"
                            className="table table-borderless align-middle transactions-list"
                          >
                            {this.state.inventorydata.length > 0 && (
                              <div className="split-assign-coa">
                                <BootstrapTable
                                  id="tbltemplate"
                                  keyField="templateid"
                                  data={this.state.misclistByItem}
                                  columns={this.state.itemColumns}
                                />
                              </div>
                            )}
                          </div>

                          <div className="d-flex action-btn align-items-center">

                            {true &&
                              <Button
                                type="button"
                                className="btn-ghost mr-auto"
                                onClick={this.handleNewRowitems}
                              >

                                <FaCirclePlus className="align-middle lh-1`" /> <span className="align-middle lh-1">Add Item</span>
                              </Button>}
                          </div>
                        </div>

                        {/* <div className="filter-sec height-172">

<br></br>
<br></br>

</div> */}
                      </div>
                    </section>
                  </div>
                )}
                {/* </Tab.Pane>
                  </Tab.Content>

                </Tab.Container> */}





                <section className="pb-3">
                  {(this.state.orderid != 0 &&
                    <div className="footer-show" >

                      <CommentBox
                        ref={this.ChildComment}
                        hotelid={this.state.hotelValue}
                        entryType={"VendorOrder"}
                        uniqueNo={this.state.formData.actionType === "copy" ? 0 : this.props.pageType === "Invoice" ? this.state.formData.uniqueno : this.state.formData.incompleteUniqueno}
                        precommentlist={this.state.htmlcomment}
                        parentScrollMethod={this.scrollToBottom}
                        updatedCommentItem={this.updatedCommentItem}
                        commentModalClose={this.CommentModalClose}

                      />
                    </div>
                    // </div>
                  )}
                </section>

                <div className={"footer-section-new viewer-panel"}
                  style={{ minWidth: "75%", width: "992px", maxWidth: "100%" }}
                >
                  <div>
                    <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                      {this.state.isStateChanged && <div className="mr-auto message">
                        <span style={{ color: '#065a8c' }}>You have unsaved changes</span>
                      </div>}

                      <div className="d-flex gap-3 align-items-center">

                        <Button
                          variant="primary"
                          type="button"
                          className="space btn btn-primary btn-discard"
                          onClick={() =>
                            this.handleDiscard(this.state.orderUniqueNo)
                          }
                        >
                          Discard
                        </Button>

                        {true
                          && (
                            <>

                              <Button
                                id="btnSaveVendor"
                                variant="primary"
                                type="button"
                                onClick={() => this.saveOrder("order", false)}
                                disabled={
                                  this.state.formData.CustomerID === 0
                                    ? false
                                    : false
                                }
                                onKeyDown={(e: any) =>
                                  this.onTAB("btnSaveVendor-1", 0, e)
                                }
                              >
                                {this.state.vendororderid > 0 ? "Update" : "Save"}
                              </Button>
                            </>
                          )}
                        {this.state.vendororderid > 0 && !this.state.editMode && this.props.receivedStatus !== 'Completed' && this.props.receivedStatus !== 'Received' && this.state.vendorordertype != 'Miscellaneouss' &&
                          <Button
                            variant="primary"
                            type="button"

                            onClick={() =>
                              this.handleEdit()
                            }
                          >
                            Add Item
                          </Button>
                        }



                        {/* {this.state.vendororderid > 0 && 
                           //!this.state.invoiceCreated &&
                            (<>

                          
                              <Button
                                id="btnDelete"
                                variant="primary"
                                type="button"
                                onClick={() => this.deleteVendorOrder()}
                                disabled={false}
                              // onKeyDown={(e: any) =>
                              //   this.onTAB("btnSave-1", 0, e)
                              // }
                              >
                                {"Delete"}
                              </Button>
                            <Button variant="primary" onClick={this.printOrder}> Print</Button>
                             <Button variant="primary" onClick={this.mailOrder}>SendEmail</Button>
                            </>)
                            } */}


                        {
                          this.state.isApproved == true && this.state.vendororderid > 0 && (
                            <>

                              <Button
                                id="btnSubmit"
                                variant="primary"
                                type="button"
                                onClick={() => this.saveOrderfinal("order", true, 0)}
                                disabled={
                                  this.state.formData.CustomerID === 0
                                    ? false
                                    : false
                                }
                                onKeyDown={(e: any) =>
                                  this.onTAB("btnSubmit", 0, e)
                                }
                              >
                                {"Approve"}
                              </Button>
                            </>)}


                        {this.props.receivedStatus != undefined && this.state.vendororderid > 0 && (this.props.receivedStatus == 'Completed') && (<>
                          <Button variant="primary" onClick={this.ConfirmSendtoBack}> Send Back To Receiving</Button>
                        </>)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {(this.state.showCustomerSlideout &&
                <Customerorderslideout
                  closeSlideOut={this.closeCustomerSlideout}
                  orderid={this.state.orderid}
                >
                </Customerorderslideout>
              )}

              {this.state.showJobSlideout && (

                <JobCreations
                  closeSlideOut={this.closeJobSlideout}
                  jobid={this.state.jobid}
                  orderid={this.state.orderid}
                  CustomerPoNumber={this.state.CustomerPoNumber}
                  poNumber={this.state.poNumber}
                  productid={this.state.productid}
                  partname={this.state.partname}
                  partNo={this.state.partNo}
                  qtyOrdered={this.state.qtyOrdered}

                  itemNo={this.state.itemNo}
                  selectedCustomer={this.state.selectedCustomer}
                  orderdate={this.state.orderdate}
                  dueDate={this.state.dueDate}
                  jobDesc={this.state.jobDesc}
                  id={this.state.orderdetailid}

                >
                </JobCreations>
              )}

              <Modal
                show={this.state.showAfterSaveModelPopup}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Vendor Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Please choose appropriate option to notify vendor.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={this.printOrder}>
                    Print
                  </Button>
                  <Button variant="primary" onClick={this.mailOrder}>SendEmail</Button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.showPopup} onHide={() => { this.setState({ showPopup: false }); }}>
                <Modal.Header>
                  <Modal.Title>Edit Item Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group controlId="formDescription">
                    <Form.Label>Item Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={this.state.currentNote}
                      onChange={(e) => { this.setState({ currentNote: e.target.value }); }}
                      placeholder="Edit the description..."
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => { this.setState({ showPopup: false }); }}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={this.handleNotesSave}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>

            </Container>
          </div>



          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}

        </div>

      </section>
    );
  }
}